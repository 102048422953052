import { BaseScreen, FlowConfig } from 'shared/api/testania'
import { screensConfig } from 'shared/config/screens'
import { ScreenId } from 'shared/config/types'
import { TransformConfigFn } from 'shared/providers/FlowManagerProvider/types'
import { logger } from 'shared/utils/logger'

export const transformStartPage = (config: FlowConfig, screensMap: typeof screensConfig) => {
  if (!config.start_page || !config.start_page.id) {
    return null
  }

  const screen = screensMap[config.start_page.id as ScreenId]

  if (!screen) {
    return {
      ...config.start_page,
      ...screensConfig.start_age,
    }
  }

  return {
    ...config.start_page,
    config: { ...screen.config, ...config.start_page.config },
  }
}

export const transformOnboardingSubflow = (
  config: FlowConfig,
  screensMap: typeof screensConfig
) => {
  return config.onboarding_flow
    .filter(({ id }) => {
      const isExist = Boolean(screensMap[id as ScreenId])

      !isExist && logger.warn(new Error(`Unknown screen ${id} in config`))

      return isExist
    })
    .map((flowScreen) => {
      const screen = screensMap[flowScreen.id as ScreenId]
      const flowScreenConfig = typeof flowScreen.config === 'object' ? flowScreen.config : {}

      return { ...flowScreen, config: { ...screen.config, ...flowScreenConfig } }
    })
}

export const transformPaymentSubflow = (config: FlowConfig, screensMap: typeof screensConfig) => {
  return config.payment_flow
    .filter(({ id }) => Boolean(screensMap[id as ScreenId]))
    .map((flowScreen) => {
      const screen = screensMap[flowScreen.id as ScreenId]
      const flowScreenConfig = typeof flowScreen.config === 'object' ? flowScreen.config : {}

      return { ...flowScreen, config: { ...flowScreenConfig, ...screen.config } }
    })
}

export const transformPostCheckoutSubflow = (
  config: BaseScreen[],
  screensMap: typeof screensConfig
) => {
  return config
    .filter(({ id }) => Boolean(screensMap[id as ScreenId]))
    .map((flowScreen) => {
      const screen = screensMap[flowScreen.id as ScreenId]
      const flowScreenConfig = typeof flowScreen.config === 'object' ? flowScreen.config : {}

      return { ...flowScreen, config: { ...flowScreenConfig, ...screen.config } }
    })
}

export const defaultTransformConfig: TransformConfigFn = (config, screensConfig) => {
  const startPage = transformStartPage(config, screensConfig)
  const onboardingSubflow = transformOnboardingSubflow(config, screensConfig)
  const paymentSubflow = transformPaymentSubflow(config, screensConfig)

  return {
    ...config,
    start_page: startPage,
    onboarding_flow: onboardingSubflow,
    payment_flow: paymentSubflow,
  }
}

export const getStartPageFromExternalSource = () => {
  if (window && window.location.search) {
    const searchParams = new URLSearchParams(window.location.search)
    const redirectPage = searchParams.get('redirect-page')

    if (redirectPage) {
      return redirectPage
    }
  }

  if (process.env.NEXT_PUBLIC_START_PAGE_ID) {
    return process.env.NEXT_PUBLIC_START_PAGE_ID
  }

  return ''
}
