/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Stresseatingresponse = (typeof Stresseatingresponse)[keyof typeof Stresseatingresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Stresseatingresponse = {
  overeat: 'overeat',
  eat_as_usual: 'eat_as_usual',
  not_eat: 'not_eat',
  other: 'other',
} as const
