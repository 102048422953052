/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Weightlossmotivationresponse =
  (typeof Weightlossmotivationresponse)[keyof typeof Weightlossmotivationresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Weightlossmotivationresponse = {
  feel_better: 'feel_better',
  increase_energy: 'increase_energy',
  enjoy_things: 'enjoy_things',
  set_example: 'set_example',
  better_life_quality: 'better_life_quality',
  other: 'other',
} as const
