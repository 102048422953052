/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Unitsenumrequest = (typeof Unitsenumrequest)[keyof typeof Unitsenumrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Unitsenumrequest = {
  metric: 'metric',
  imperial: 'imperial',
} as const
