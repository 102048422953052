// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfilePacifier = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
      <path
        fill="#878E95"
        d="M12.5 22c-5.483 0-10-4.517-10-10 0-5.492 4.508-10 10-10 5.483 0 10 4.508 10 10 0 5.483-4.508 10-10 10M8.011 10.684h1.146v-.55c0-.435.237-.662.673-.662h1.553c.435 0 .663.227.663.662v.55h.937v-.55c0-.435.237-.662.672-.662h1.506c.445 0 .682.227.682.662v.55h1.146V9.472c0-.834-.445-1.26-1.27-1.26H9.28c-.824 0-1.269.426-1.269 1.26zM6.922 15.39c0 .237.161.388.398.388h.265c.237 0 .407-.151.407-.388v-.587c.038.01.2.019.322.019h8.372c.132 0 .284-.01.331-.019v.587c0 .237.161.388.398.388h.274c.228 0 .389-.151.389-.388v-2.632c0-.89-.493-1.373-1.383-1.373h-8.38c-.9 0-1.393.482-1.393 1.373z"
      />
    </svg>
  )
}
export { IconProfilePacifier }
