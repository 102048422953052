import { Logger, isLevel, transports } from 'libs/logger'

import { IS_DEVTOOLS_AVAILABLE } from 'shared/constants/devtools'
import { StorageValue } from 'shared/utils/storage'

import * as customTransports from './transports'

const levelValue = new StorageValue({ key: 'frr:log_level' })

export const logger = new Logger({
  transports: [
    transports.console,
    customTransports.sentry,
    ...(IS_DEVTOOLS_AVAILABLE ? [customTransports.devtools] : []),
  ],
})

// save logger level to storage
logger.events.onLevelChange.on((value) => {
  levelValue.set(value)
})

export const initLogger = () => {
  const initialLevel = process.env.APP_ENV === 'prod' ? 'error' : 'info'

  // eslint-disable-next-line no-underscore-dangle
  global.window.__setAppLogLevel__ = (value?: unknown) => {
    if (isLevel(value)) {
      logger.level = value
    }
  }

  const levelFromStorage = levelValue.get()
  const level = isLevel(levelFromStorage) ? levelFromStorage : initialLevel

  logger.level = level
}
