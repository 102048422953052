/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Buttonenumrequest = (typeof Buttonenumrequest)[keyof typeof Buttonenumrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Buttonenumrequest = {
  default: 'default',
  circle: 'circle',
  inverted: 'inverted',
} as const
