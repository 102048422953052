/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Hungrytimeofdayresponse =
  (typeof Hungrytimeofdayresponse)[keyof typeof Hungrytimeofdayresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Hungrytimeofdayresponse = {
  morning: 'morning',
  afternoon: 'afternoon',
  evening: 'evening',
  night: 'night',
} as const
