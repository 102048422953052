/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Yesnosometimesresponse =
  (typeof Yesnosometimesresponse)[keyof typeof Yesnosometimesresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Yesnosometimesresponse = {
  yes: 'yes',
  no: 'no',
  sometimes: 'sometimes',
} as const
