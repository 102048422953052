/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Registrationsourceenumresponse =
  (typeof Registrationsourceenumresponse)[keyof typeof Registrationsourceenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Registrationsourceenumresponse = {
  web: 'web',
  ios: 'ios',
} as const
