/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Yesnomayberesponse = (typeof Yesnomayberesponse)[keyof typeof Yesnomayberesponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Yesnomayberesponse = {
  yes: 'yes',
  no: 'no',
  maybe: 'maybe',
} as const
