import Cookies from 'js-cookie'
import React, { createContext, useContext, useEffect, useState, useMemo, ReactNode } from 'react'

import { getQueryValueByKey } from 'shared/utils/common'

interface AuthTokenContextInterface {
  token: string
  isReady: boolean
  setToken: (token: string) => void
}

const AuthTokenContext = createContext<AuthTokenContextInterface | null>(null)

function useAuthToken() {
  const context = useContext(AuthTokenContext)

  if (!context) {
    throw new Error(`useAuthToken must be used within AuthToken`)
  }

  return context
}

interface AuthTokenProps {
  queryName?: string
  cookieName?: string
  children?: ReactNode
}

const AuthTokenProvider = ({
  children,
  cookieName = 'token',
  queryName = 'token',
}: AuthTokenProps) => {
  const [token, setToken] = useState('')
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const tokenFromQuery = getQueryValueByKey(queryName)
    const tokenFromCookie = Cookies.get(cookieName)
    const token = tokenFromQuery || tokenFromCookie

    if (token) {
      setToken(token)
    }

    setIsReady(true)
  }, [queryName, cookieName])

  useEffect(() => {
    if (token) {
      Cookies.set(cookieName, token, {
        expires: 365, // 1 year
        sameSite: 'Lax',
        secure: global.location.protocol.includes('https'),
        path: '/',
      })

      return
    }

    Cookies.remove(cookieName)
  }, [token, cookieName])

  const api = useMemo<AuthTokenContextInterface>(
    () => ({
      token,
      isReady,
      setToken,
    }),
    [token, isReady, setToken]
  )

  return <AuthTokenContext.Provider value={api}>{children}</AuthTokenContext.Provider>
}

export { AuthTokenProvider, useAuthToken, AuthTokenContext }
