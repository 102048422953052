import { BaseScreen, PaymentScreen, FlowConfig } from 'shared/api/testania'
import { screensConfig } from 'shared/config/screens'

export enum FlowName {
  Onboarding = 'onboardingFlow',
  Payment = 'paymentFlow',
  PostCheckout = 'postCheckoutFlow',
}

export type FlowItem = (typeof FlowName)[keyof typeof FlowName]

export interface FlowsMap {
  [FlowName.Onboarding]: FlowConfig['onboarding_flow']
  [FlowName.Payment]: FlowConfig['payment_flow']
  [FlowName.PostCheckout]: BaseScreen[]
}

export type PaymentFlowObj = Record<string, PaymentScreen[]>

export type TransformConfigFn = (config: FlowConfig, screensMap: typeof screensConfig) => FlowConfig
