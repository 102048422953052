export const withResolvers = () => {
  let resolve: (value: unknown) => void = () => {}

  let reject: (reason?: any) => void = () => {}

  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })

  return { promise, resolve, reject }
}
