/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Scenariotyperequest = (typeof Scenariotyperequest)[keyof typeof Scenariotyperequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Scenariotyperequest = {
  help_button: 'help_button',
  survey: 'survey',
  include: 'include',
  ar: 'ar',
} as const
