import { useCallback } from 'react'

import { useAnalytics } from 'shared/providers/AnalyticsProvider'
import { useOneTrust } from 'shared/providers/OneTrustProvider'

import { FlowManager } from '../helpers/FlowManager'

export const usePreparePaymentFlow = () => {
  const {
    operations: { allowAllCookies },
  } = useOneTrust()

  const { clearEventsPool } = useAnalytics()

  const preparePaymentFlow = () => {
    FlowManager.setOnboardingFinished()
    // clear events pool so that previously logged events are not sent when all cookies are forcibly allowed on payment screen [sot_w-175]
    clearEventsPool()
    allowAllCookies()
  }

  return useCallback(preparePaymentFlow, [clearEventsPool, allowAllCookies])
}
