/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Blocktyperesponse = (typeof Blocktyperesponse)[keyof typeof Blocktyperesponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Blocktyperesponse = {
  text_block: 'text_block',
  image_block: 'image_block',
  four_images_block: 'four_images_block',
  button: 'button',
} as const
