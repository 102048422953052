/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Behaviortriggerrequest =
  (typeof Behaviortriggerrequest)[keyof typeof Behaviortriggerrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Behaviortriggerrequest = {
  food_around_me: 'food_around_me',
  boredom: 'boredom',
  emotional_state: 'emotional_state',
  other_people_eating: 'other_people_eating',
  hunger: 'hunger',
  something_else: 'something_else',
} as const
