/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Nheprofiletyperesponseenumresponse =
  (typeof Nheprofiletyperesponseenumresponse)[keyof typeof Nheprofiletyperesponseenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Nheprofiletyperesponseenumresponse = {
  emotional: 'emotional',
  mindless: 'mindless',
  combined: 'combined',
} as const
