/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Yesnorequest = (typeof Yesnorequest)[keyof typeof Yesnorequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Yesnorequest = {
  yes: 'yes',
  no: 'no',
} as const
