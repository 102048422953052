/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Contentfulsyncstatusresponse =
  (typeof Contentfulsyncstatusresponse)[keyof typeof Contentfulsyncstatusresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Contentfulsyncstatusresponse = {
  scheduled: 'scheduled',
  processing: 'processing',
  error: 'error',
  finished: 'finished',
} as const
