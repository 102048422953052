import React from 'react'

export type DeviceOs = '' | 'android' | 'ios' | 'unknown'

const OS_REGEXP_MAP = {
  android: new RegExp('Android', 'i'),
  ios: new RegExp('\\biPhone.*Mobile|\\biPod|\\biPad|AppleCoreMedia', 'i'),
}

export const detectDeviceOs = (ua?: string): DeviceOs => {
  if (!ua) {
    return ''
  }

  if (OS_REGEXP_MAP.android.test(ua)) {
    return 'android'
  }

  if (OS_REGEXP_MAP.ios.test(ua)) {
    return 'ios'
  }

  return 'unknown'
}

export const getUserAgent = () => global?.navigator?.userAgent

export const detectSocialWebViewBrowser = () => {
  const userAgent = getUserAgent()
  const browsers = ['Instagram', 'FBAN', 'FBAV']

  const isSocialWebViewBrowser = browsers.some((browser) => userAgent.indexOf(browser) > -1)

  return isSocialWebViewBrowser
}

export const useDeviceOs = () => {
  const [os, setOS] = React.useState<DeviceOs>('')

  React.useEffect(() => {
    setOS(detectDeviceOs(getUserAgent()))
  }, [])

  return os
}
