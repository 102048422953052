/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Weightlossfocusrequest =
  (typeof Weightlossfocusrequest)[keyof typeof Weightlossfocusrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Weightlossfocusrequest = {
  healthy_habits: 'healthy_habits',
  nutrition: 'nutrition',
  activity: 'activity',
  other: 'other',
} as const
