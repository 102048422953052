// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfilePacifierWhite = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
      <g filter="url(#prefix__a)">
        <path
          fill="#fff"
          d="M20.5 36.25c-9.047 0-16.5-7.453-16.5-16.5 0-9.062 7.438-16.5 16.5-16.5 9.047 0 16.5 7.438 16.5 16.5 0 9.047-7.437 16.5-16.5 16.5m-7.406-18.672h1.89v-.906c0-.719.391-1.094 1.11-1.094h2.562c.719 0 1.094.375 1.094 1.094v.906h1.547v-.906c0-.719.39-1.094 1.11-1.094h2.484c.734 0 1.125.375 1.125 1.094v.906h1.89v-2c0-1.375-.734-2.078-2.093-2.078H15.188c-1.36 0-2.094.703-2.094 2.078zm-1.797 7.766c0 .39.265.64.656.64h.438c.39 0 .672-.25.672-.64v-.969c.062.016.328.031.53.031h13.813c.219 0 .469-.015.547-.031v.969c0 .39.266.64.656.64h.453c.375 0 .641-.25.641-.64V21c0-1.469-.812-2.266-2.281-2.266H13.594c-1.485 0-2.297.797-2.297 2.266z"
        />
      </g>
      <defs>
        <filter
          id="prefix__a"
          width={33}
          height={34}
          x={4}
          y={3.25}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1820_7600" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1820_7600" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
export { IconProfilePacifierWhite }
