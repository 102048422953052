import { HeightUnit } from './constants/HeightUnit'
import { UnitsSystem } from './constants/UnitsSystem'
import { WeightUnit } from './constants/WeightUnit'
import { getUnitsSystemByWeightUnit } from './helper'
import { Action, State } from './types'

export const INITIAL_STATE: State = {
  country: '',
  unitSystem: UnitsSystem.METRIC,
  weightUnits: Object.values(WeightUnit) as Array<WeightUnit>,
  heightUnits: Object.values(HeightUnit) as Array<HeightUnit>,
  ageGroup: '',
  gender: '',
  currentWeight: {
    unit: '',
    value: '',
  },
  targetWeight: {
    unit: '',
    value: '',
  },
  height: {
    unit: '',
    value: '',
  },
  /* PLOP_INITIAL_STATE */
  startWhatSee: undefined,
  sweetDrink: undefined,
  adhdResultsImpression: [],
  eatingBhvResultsImpression: [],
  hadADHDSymptoms: undefined,
  adhdFocus: undefined,
  adhdYN2: undefined,
  adhdYN1: undefined,
  adhdDiagnosed: undefined,
  adhdStatement9: undefined,
  adhdReread: undefined,
  adhdLoseTrack: undefined,
  stressImage: [],
  foodRelax: undefined,
  btBenefits: undefined,
  knowBehavioral: undefined,
  unconsciousEating: undefined,
  baseStatement3: undefined,
  baseStatement2: undefined,
  baseStatement1: undefined,
  selfImprove: [],
  em4: undefined,
  em3: undefined,
  em2: undefined,
  em1: undefined,
  addGoal1: [],
  addImprove: [],
  adhdStatement8: undefined,
  adhdStatement7: undefined,
  adhdStatement6: undefined,
  adhdStatement5: undefined,
  adhdStatement4: undefined,
  adhdStatement3: undefined,
  adhdStatement2: undefined,
  adhdStatement1: undefined,
  adhdSuspect: [],
  goalsAdhd: [],
  adhdReason: undefined,
  believeAdhd: undefined,
  knowADHD: undefined,
  backIssues: undefined,
  eatingBh: [],
  fitnessWalking: undefined,
  preferEatPlace: undefined,
  additionalGoal: [],
  exploreAdd: [],
  snackTime: undefined,
  goalMind: undefined,
  weightLossFailReasons: [],
  motivationChange: undefined,
  heardFromProfessional: undefined,
  previousMentalHealth: [],
  previousAttempts: [],
  doThingsWhileEat: undefined,
  eatingAsDealingWithEmotions: undefined,
  emptyPlateWhenFull: undefined,
  motivation: undefined,
  lifeEvents: [],
  badHabits: [],
  sleep: undefined,
  healthConditions: [],
  medications: [],
  water: undefined,
  walking: undefined,
  stairs: undefined,
  jobActive: undefined,
  jobLoad: undefined,
  mealsFeel: undefined,
  timeDinner: undefined,
  timeLunch: undefined,
  timeBreakfast: undefined,
  lastHappyWeight: undefined,
  targetBodyType: undefined,
  healthGoals: [],
  currentBodyType: undefined,
  focus: [],
  targetZones: [],
  fitnessLevel: undefined,
  lifeBusyness: [],
  weightGainReasons: [],
  healthIssues: [],
  triedDiets: undefined,
  emailConsent: undefined,
  nutritionStressEating: undefined,
  nutritionHowToFitInMyLife: undefined,
  behaviorPush: undefined,
  behaviorGuilt: undefined,
  behaviorSocial: undefined,
  behaviorStPlate: undefined,
  behaviorStSocialize: undefined,
  weightLossPace: undefined,
  age: undefined,
  occasion: undefined,
  occasionDate: undefined,
  healthGoalReason: undefined,
}

export const USER_PROPERTIES_KEYS = Object.keys(INITIAL_STATE) as Array<keyof typeof INITIAL_STATE>

// eslint-disable-next-line complexity
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_AGE_GROUP': {
      return { ...state, ageGroup: action.payload }
    }

    case 'SET_GENDER': {
      return { ...state, gender: action.payload }
    }

    case 'SET_CURRENT_WEIGHT': {
      const { unit } = action.payload
      const unitSystem = getUnitsSystemByWeightUnit(unit)

      return {
        ...state,
        unitSystem,
        currentWeight: { ...action.payload },
      }
    }

    case 'SET_TARGET_WEIGHT': {
      const { unit } = action.payload
      const unitSystem = getUnitsSystemByWeightUnit(unit)

      return {
        ...state,
        unitSystem,
        targetWeight: { ...action.payload },
      }
    }

    /* PLOP_REDUCER_ACTION */
    case 'SET_START_WHAT_SEE': {
      return { ...state, startWhatSee: action.payload }
    }

    case 'SET_SWEET_DRINK': {
      return { ...state, sweetDrink: action.payload }
    }

    case 'SET_ADHD_RESULTS_IMPRESSION': {
      return { ...state, adhdResultsImpression: action.payload }
    }

    case 'SET_EATING_BHV_RESULTS_IMPRESSION': {
      return { ...state, eatingBhvResultsImpression: action.payload }
    }

    case 'SET_HAD_ADHD_SYMPTOMS': {
      return { ...state, hadADHDSymptoms: action.payload }
    }

    case 'SET_ADHD_FOCUS': {
      return { ...state, adhdFocus: action.payload }
    }

    case 'SET_ADHD_Y_N2': {
      return { ...state, adhdYN2: action.payload }
    }

    case 'SET_ADHD_Y_N1': {
      return { ...state, adhdYN1: action.payload }
    }

    case 'SET_ADHD_DIAGNOSED': {
      return { ...state, adhdDiagnosed: action.payload }
    }

    case 'SET_ADHD_STATEMENT9': {
      return { ...state, adhdStatement9: action.payload }
    }

    case 'SET_ADHD_REREAD': {
      return { ...state, adhdReread: action.payload }
    }

    case 'SET_ADHD_LOSE_TRACK': {
      return { ...state, adhdLoseTrack: action.payload }
    }

    case 'SET_STRESS_IMAGE': {
      return { ...state, stressImage: action.payload }
    }

    case 'SET_FOOD_RELAX': {
      return { ...state, foodRelax: action.payload }
    }

    case 'SET_BT_BENEFITS': {
      return { ...state, btBenefits: action.payload }
    }

    case 'SET_KNOW_BEHAVIORAL': {
      return { ...state, knowBehavioral: action.payload }
    }

    case 'SET_UNCONSCIOUS_EATING': {
      return { ...state, unconsciousEating: action.payload }
    }

    case 'SET_BASE_STATEMENT3': {
      return { ...state, baseStatement3: action.payload }
    }

    case 'SET_BASE_STATEMENT2': {
      return { ...state, baseStatement2: action.payload }
    }

    case 'SET_BASE_STATEMENT1': {
      return { ...state, baseStatement1: action.payload }
    }

    case 'SET_SELF_IMPROVE': {
      return { ...state, selfImprove: action.payload }
    }

    case 'SET_EM4': {
      return { ...state, em4: action.payload }
    }

    case 'SET_EM3': {
      return { ...state, em3: action.payload }
    }

    case 'SET_EM2': {
      return { ...state, em2: action.payload }
    }

    case 'SET_EM1': {
      return { ...state, em1: action.payload }
    }

    case 'SET_ADD_GOAL1': {
      return { ...state, addGoal1: action.payload }
    }

    case 'SET_ADD_IMPROVE': {
      return { ...state, addImprove: action.payload }
    }

    case 'SET_ADHD_STATEMENT8': {
      return { ...state, adhdStatement8: action.payload }
    }

    case 'SET_ADHD_STATEMENT7': {
      return { ...state, adhdStatement7: action.payload }
    }

    case 'SET_ADHD_STATEMENT6': {
      return { ...state, adhdStatement6: action.payload }
    }

    case 'SET_ADHD_STATEMENT5': {
      return { ...state, adhdStatement5: action.payload }
    }

    case 'SET_ADHD_STATEMENT4': {
      return { ...state, adhdStatement4: action.payload }
    }

    case 'SET_ADHD_STATEMENT3': {
      return { ...state, adhdStatement3: action.payload }
    }

    case 'SET_ADHD_STATEMENT2': {
      return { ...state, adhdStatement2: action.payload }
    }

    case 'SET_ADHD_STATEMENT1': {
      return { ...state, adhdStatement1: action.payload }
    }

    case 'SET_ADHD_SUSPECT': {
      return { ...state, adhdSuspect: action.payload }
    }

    case 'SET_GOALS_ADHD': {
      return { ...state, goalsAdhd: action.payload }
    }

    case 'SET_ADHD_REASON': {
      return { ...state, adhdReason: action.payload }
    }

    case 'SET_BELIEVE_ADHD': {
      return { ...state, believeAdhd: action.payload }
    }

    case 'SET_KNOW_ADHD': {
      return { ...state, knowADHD: action.payload }
    }

    case 'SET_BACK_ISSUES': {
      return { ...state, backIssues: action.payload }
    }

    case 'SET_EATING_BH': {
      return { ...state, eatingBh: action.payload }
    }

    case 'SET_FITNESS_WALKING': {
      return { ...state, fitnessWalking: action.payload }
    }

    case 'SET_PREFER_EAT_PLACE': {
      return { ...state, preferEatPlace: action.payload }
    }

    case 'SET_ADDITIONAL_GOAL': {
      return { ...state, additionalGoal: action.payload }
    }

    case 'SET_EXPLORE_ADD': {
      return { ...state, exploreAdd: action.payload }
    }

    case 'SET_SNACK_TIME': {
      return { ...state, snackTime: action.payload }
    }

    case 'SET_GOAL_MIND': {
      return { ...state, goalMind: action.payload }
    }

    case 'SET_WEIGHT_LOSS_FAIL_REASONS': {
      return { ...state, weightLossFailReasons: action.payload }
    }

    case 'SET_MOTIVATION_CHANGE': {
      return { ...state, motivationChange: action.payload }
    }

    case 'SET_HEARD_FROM_PROFESSIONAL': {
      return { ...state, heardFromProfessional: action.payload }
    }

    case 'SET_PREVIOUS_MENTAL_HEALTH': {
      return { ...state, previousMentalHealth: action.payload }
    }

    case 'SET_PREVIOUS_ATTEMPTS': {
      return { ...state, previousAttempts: action.payload }
    }

    case 'SET_DO_THINGS_WHILE_EAT': {
      return { ...state, doThingsWhileEat: action.payload }
    }

    case 'SET_EATING_AS_DEALING_WITH_EMOTIONS': {
      return { ...state, eatingAsDealingWithEmotions: action.payload }
    }

    case 'SET_EMPTY_PLATE_WHEN_FULL': {
      return { ...state, emptyPlateWhenFull: action.payload }
    }

    case 'SET_MOTIVATION': {
      return { ...state, motivation: action.payload }
    }

    case 'SET_LIFE_EVENTS': {
      return { ...state, lifeEvents: action.payload }
    }

    case 'SET_BAD_HABITS': {
      return { ...state, badHabits: action.payload }
    }

    case 'SET_SLEEP': {
      return { ...state, sleep: action.payload }
    }

    case 'SET_HEALTH_CONDITIONS': {
      return { ...state, healthConditions: action.payload }
    }

    case 'SET_MEDICATIONS': {
      return { ...state, medications: action.payload }
    }

    case 'SET_WATER': {
      return { ...state, water: action.payload }
    }

    case 'SET_WALKING': {
      return { ...state, walking: action.payload }
    }

    case 'SET_STAIRS': {
      return { ...state, stairs: action.payload }
    }

    case 'SET_JOB_ACTIVE': {
      return { ...state, jobActive: action.payload }
    }

    case 'SET_JOB_LOAD': {
      return { ...state, jobLoad: action.payload }
    }

    case 'SET_MEALS_FEEL': {
      return { ...state, mealsFeel: action.payload }
    }

    case 'SET_TIME_DINNER': {
      return { ...state, timeDinner: action.payload }
    }

    case 'SET_TIME_LUNCH': {
      return { ...state, timeLunch: action.payload }
    }

    case 'SET_TIME_BREAKFAST': {
      return { ...state, timeBreakfast: action.payload }
    }

    case 'SET_LAST_HAPPY_WEIGHT': {
      return { ...state, lastHappyWeight: action.payload }
    }

    case 'SET_TARGET_BODY_TYPE': {
      return { ...state, targetBodyType: action.payload }
    }

    case 'SET_HEALTH_GOALS': {
      return { ...state, healthGoals: action.payload }
    }

    case 'SET_CURRENT_BODY_TYPE': {
      return { ...state, currentBodyType: action.payload }
    }

    case 'SET_FOCUS': {
      return { ...state, focus: action.payload }
    }

    case 'SET_TARGET_ZONES': {
      return { ...state, targetZones: action.payload }
    }

    case 'SET_FITNESS_LEVEL': {
      return { ...state, fitnessLevel: action.payload }
    }

    case 'SET_LIFE_BUSYNESS': {
      return { ...state, lifeBusyness: action.payload }
    }

    case 'SET_WEIGHT_GAIN_REASONS': {
      return { ...state, weightGainReasons: action.payload }
    }

    case 'SET_HEALTH_ISSUES': {
      return { ...state, healthIssues: action.payload }
    }

    case 'SET_TRIED_DIETS': {
      return { ...state, triedDiets: action.payload }
    }

    case 'SET_EMAIL_CONSENT': {
      return { ...state, emailConsent: action.payload }
    }

    case 'SET_NUTRITION_STRESS_EATING': {
      return { ...state, nutritionStressEating: action.payload }
    }

    case 'SET_NUTRITION_HOW_TO_FIT_IN_MY_LIFE': {
      return { ...state, nutritionHowToFitInMyLife: action.payload }
    }

    case 'SET_BEHAVIOR_PUSH': {
      return { ...state, behaviorPush: action.payload }
    }

    case 'SET_BEHAVIOR_GUILT': {
      return { ...state, behaviorGuilt: action.payload }
    }

    case 'SET_BEHAVIOR_SOCIAL': {
      return { ...state, behaviorSocial: action.payload }
    }

    case 'SET_BEHAVIOR_ST_PLATE': {
      return { ...state, behaviorStPlate: action.payload }
    }

    case 'SET_BEHAVIOR_ST_SOCIALIZE': {
      return { ...state, behaviorStSocialize: action.payload }
    }

    case 'SET_WEIGHT_LOSS_PACE': {
      return { ...state, weightLossPace: action.payload }
    }

    case 'SET_HEIGHT': {
      return { ...state, height: action.payload }
    }

    case 'SET_AGE': {
      return { ...state, age: action.payload }
    }

    case 'SET_OCCASION': {
      return { ...state, occasion: action.payload }
    }

    case 'SET_OCCASION_DATE': {
      return { ...state, occasionDate: action.payload }
    }

    case 'SET_HEALTH_GOAL_REASON': {
      return { ...state, healthGoalReason: action.payload }
    }

    case 'SET_COUNTRY': {
      return { ...state, country: action.payload }
    }

    case 'SET_UNIT_SYSTEM': {
      return { ...state, unitSystem: action.payload }
    }

    case 'SET_WHOLE_STATE_FROM_DEVTOOLS': {
      return { ...state, ...action.payload }
    }

    default: {
      // @ts-expect-error
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
