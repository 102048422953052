export enum OneTrustCookies {
  StrictlyNecessary = 'C0001',
  Performance = 'C0002',
  Functional = 'C0003',
  Targeting = 'C0004',
}

export type ExtendedWindow = Window &
  typeof globalThis & {
    OneTrust: {
      OnConsentChanged: (callback: () => void) => void
      IsAlertBoxClosed: () => boolean
      AllowAll: () => void
    }
    OnetrustActiveGroups: string
    onOneTrustLoaded: () => void
  }
