/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Taskbackgroundenumresponse =
  (typeof Taskbackgroundenumresponse)[keyof typeof Taskbackgroundenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Taskbackgroundenumresponse = {
  lighter: 'lighter',
  darker: 'darker',
} as const
