import { OneTrustCookies } from '../types'

export const ALL_ONE_TRUST_COOKIES_TYPES = [
  OneTrustCookies.Functional,
  OneTrustCookies.Performance,
  OneTrustCookies.StrictlyNecessary,
  OneTrustCookies.Targeting,
]

export const OT_CDN_URL = 'https://cdn.cookielaw.org'

export const OT_GEOLOCATION_SERVICE_URL = 'https://geolocation.onetrust.com'

export const OT_BANNER_ROOT_ELEMENT_ID = 'onetrust-consent-sdk'
