// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileStoic = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#878E95"
        d="M8.489 21.5A6.482 6.482 0 0 1 2 15.021a6.474 6.474 0 0 1 3.516-5.754v-.306c0-3.562 2.903-6.461 6.48-6.461 3.576 0 6.48 2.899 6.48 6.461 0 .105 0 .201-.009.297A6.477 6.477 0 0 1 22 15.021a6.48 6.48 0 0 1-9.996 5.44A6.464 6.464 0 0 1 8.49 21.5Zm0-12.949c1.294 0 2.5.384 3.515 1.04a6.464 6.464 0 0 1 4.618-.944 4.633 4.633 0 0 0-4.626-4.305 4.633 4.633 0 0 0-4.627 4.305 6.755 6.755 0 0 1 1.12-.096Zm0 1.851a4.79 4.79 0 0 0-.875.079c.333.96.971 1.78 1.81 2.34a6.211 6.211 0 0 1 1.12-1.939 4.533 4.533 0 0 0-2.055-.48Zm7.03 0a4.57 4.57 0 0 0-2.063.48 6.27 6.27 0 0 1 1.12 1.93 4.64 4.64 0 0 0 1.8-2.331 4.6 4.6 0 0 0-.856-.079Zm0 9.256a4.636 4.636 0 0 0 4.636-4.637 4.626 4.626 0 0 0-2.064-3.85 6.487 6.487 0 0 1-3.13 3.553c0 .096.008.201.008.297 0 1.58-.569 3.03-1.513 4.148.63.314 1.33.489 2.064.489Zm-7.03 0a4.54 4.54 0 0 0 2.055-.49 6.4 6.4 0 0 1-1.504-4.147v-.288a6.536 6.536 0 0 1-3.14-3.554 4.614 4.614 0 0 0-2.055 3.842 4.637 4.637 0 0 0 4.644 4.637Zm3.507-6.06c.297 0 .594-.026.874-.079a4.633 4.633 0 0 0-.866-1.51 4.631 4.631 0 0 0-.865 1.51c.28.053.568.079.857.079Zm0 1.842c-.376 0-.744-.026-1.102-.096a4.618 4.618 0 0 0 1.11 2.698 4.61 4.61 0 0 0 1.102-2.698 6.573 6.573 0 0 1-1.11.096Z"
      />
    </svg>
  )
}
export { IconProfileStoic }
