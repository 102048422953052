export const hashString = async (message: string) => {
  const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array

  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8) // hash the message

  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array

  // convert bytes to hex string
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
}

export const generateHash = () =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
