import {
  lookupLocaleFromPath,
  lookupLocaleFromQuery,
  lookupLocaleFromLocalStorage,
  lookupLocaleFromCookie,
  cacheLocaleInLocalStorage,
  cacheLocaleInCookie,
  lookupLocaleFromBrowser,
} from './helpers'

export const enum LocaleSource {
  PATH = 'path',
  QUERY = 'query',
  LOCAL_STORAGE = 'localStorage',
  COOKIE = 'cookie',
  BROWSER = 'browser',
}

export const enum CacheType {
  LOCAL_STORAGE = 'localStorage',
  COOKIE = 'cookie',
}

export const LOCALE_SOURCE_MAP = {
  [LocaleSource.PATH]: lookupLocaleFromPath,
  [LocaleSource.QUERY]: lookupLocaleFromQuery,
  [LocaleSource.LOCAL_STORAGE]: lookupLocaleFromLocalStorage,
  [LocaleSource.COOKIE]: lookupLocaleFromCookie,
  [LocaleSource.BROWSER]: lookupLocaleFromBrowser,
}

export const CACHE_TYPE_MAP = {
  [CacheType.LOCAL_STORAGE]: cacheLocaleInLocalStorage,
  [CacheType.COOKIE]: cacheLocaleInCookie,
}
