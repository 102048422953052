import {
  BrowserStorageInterface,
  StorageType,
  StorageValueInterface,
  StorageValueOptions,
  updateValueCallbackFn,
} from './storage.types'

const testStorageWritable = (name: StorageType = 'localStorage') => {
  const TEST_KEY = 'localStorage:test'

  if (!global.window) {
    return false
  }

  const storage: BrowserStorageInterface = global.window[name]

  try {
    storage.setItem(TEST_KEY, 'test')
    storage.removeItem(TEST_KEY)

    return true
  } catch (e) {
    return false
  }
}

const isLocalStorageWritable = testStorageWritable('localStorage')

class StorageValue implements StorageValueInterface {
  private value: string = ''
  private readonly key: string = ''
  private readonly storage: BrowserStorageInterface
  private readonly wnd: typeof window
  private persistable: boolean = true
  private updateValueCallback = (_newValue: string, _oldValue: string) => {}

  constructor({
    key = '',
    storage = global.window?.localStorage,
    wnd = global.window,
    persistable = isLocalStorageWritable,
  }: StorageValueOptions) {
    this.key = key
    this.storage = storage
    this.wnd = wnd
    this.persistable = persistable

    if (this.storage) {
      this.value = this.storage.getItem(key) || ''
    }

    if (this.wnd && this.persistable) {
      this.wnd.addEventListener('storage', ({ key, newValue }: StorageEvent) => {
        if (key === this.key && newValue !== this.value) {
          this.set(newValue || '')
        }
      })
    }
  }

  set = (newValue: string) => {
    const oldValue = this.value
    this.value = newValue

    if (oldValue !== newValue) {
      this.updateValueCallback(newValue, oldValue)

      if (this.persistable && this.storage) {
        this.storage.setItem(this.key, newValue)
      }
    }
  }

  get = () => {
    return this.value
  }

  clear = () => {
    this.set('')
  }

  onUpdate = (callback: updateValueCallbackFn) => {
    this.updateValueCallback = callback

    return () => {
      this.updateValueCallback = () => {}
    }
  }
}

export { StorageValue }
