import { ExtendedWindow, OneTrustCookies } from '../types'

const OT_SDK_SCRIPT_ID = 'ot-sdk-script'
const OT_AUTO_BLOCK_SCRIPT_ID = 'ot-auto-block-script'

export class OneTrustManager {
  isInited: boolean

  constructor() {
    this.isInited = false
  }

  init({ domainId, withAutoBlock = false }: { domainId?: string; withAutoBlock?: boolean }) {
    if (!(domainId && window)) {
      return
    }

    const { head } = document

    const isExistSdk = document.getElementById(OT_SDK_SCRIPT_ID)

    if (!isExistSdk) {
      const otSDKStubScript = document.createElement('script')
      otSDKStubScript.async = true
      otSDKStubScript.type = 'text/javascript'
      otSDKStubScript.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`
      otSDKStubScript.id = OT_SDK_SCRIPT_ID
      otSDKStubScript.setAttribute('data-document-language', 'true')
      otSDKStubScript.setAttribute('data-domain-script', domainId)

      const otOptanonWrapperScript = document.createElement('script')
      otOptanonWrapperScript.async = false
      otOptanonWrapperScript.type = 'text/javascript'
      const optanonWrapperScriptValue = document.createTextNode(
        'function OptanonWrapper() { onOneTrustLoaded() }'
      )
      otOptanonWrapperScript.appendChild(optanonWrapperScriptValue)

      head.insertBefore(otSDKStubScript, head.firstChild)
      head.insertBefore(otOptanonWrapperScript, head.firstChild)
    }

    if (withAutoBlock) {
      const isExist = document.getElementById(OT_AUTO_BLOCK_SCRIPT_ID)

      if (!isExist) {
        const otAutoBlockScript = document.createElement('script')
        otAutoBlockScript.async = false
        otAutoBlockScript.type = 'text/javascript'
        otAutoBlockScript.src = `https://cdn.cookielaw.org/consent/${domainId}/OtAutoBlock.js`
        otAutoBlockScript.id = OT_AUTO_BLOCK_SCRIPT_ID
        head.insertBefore(otAutoBlockScript, head.firstChild)
      }
    }

    this.isInited = true
  }

  checkAreAllCookiesAllowed(allowedCookies: OneTrustCookies[] | null) {
    return [
      OneTrustCookies.Functional,
      OneTrustCookies.Targeting,
      OneTrustCookies.Performance,
    ].every((cookieType) => {
      return allowedCookies?.includes(cookieType)
    })
  }

  allowAllCookies() {
    if (!window) {
      return
    }

    const OneTrust = this.getOneTrust()

    OneTrust.AllowAll()
  }

  getSelectedCookiesOptions({
    onSelect,
  }: {
    onSelect: (selectedOptions: OneTrustCookies[]) => void
  }) {
    const OneTrust = this.getOneTrust()

    const isOneTrustBannerClosed = OneTrust.IsAlertBoxClosed()

    // call listener if cookie banner was closed during previous session
    if (isOneTrustBannerClosed) {
      const activeGroups = this.getActiveGroups()

      if (activeGroups) {
        onSelect(activeGroups)
      }
    }

    // subscribe for consent update
    OneTrust.OnConsentChanged(() => {
      const activeGroups = this.getActiveGroups()

      if (activeGroups) {
        onSelect(activeGroups)
      }
    })
  }

  private getActiveGroups() {
    const activeGroups = (window as ExtendedWindow)?.OnetrustActiveGroups?.split(
      ','
    ) as OneTrustCookies[]

    // OnetrustActiveGroups are having strange value, it's started by "," and finished by ","
    // That's why we need to remove first and last element from 'activeGroups' array
    const filteredActiveGroups = activeGroups?.filter((cookieType) => !!cookieType)

    return filteredActiveGroups
  }

  private getOneTrust = () => {
    const OneTrust = (window as ExtendedWindow)?.OneTrust

    if (!OneTrust) {
      throw new Error('otSDK is not loaded!')
    }

    return OneTrust
  }
}
