export type Gender = 'male' | 'female' | 'other'
export type BinaryGender = 'male' | 'female'

export const Gender = {
  MALE: 'male' as Gender,
  FEMALE: 'female' as Gender,
  OTHER: 'other' as Gender,
}

export enum WeightGoal {
  GAIN = 'gain',
  LOSE = 'LOSE',
  MAINTAIN = 'maintain',
}

export enum BMIGroup {
  UNDERWEIGHT = 'underweight',
  NORMAL = 'normal',
  OVERWEIGHT = 'overweight',
  HEAVY_OVERWEIGHT = 'heavy-overweight',
  OBESE = 'obese',
}
