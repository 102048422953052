/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Applesubscriptionstatusresponse =
  (typeof Applesubscriptionstatusresponse)[keyof typeof Applesubscriptionstatusresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Applesubscriptionstatusresponse = {
  created: 'created',
  active: 'active',
  cancelled: 'cancelled',
  expired: 'expired',
  refunded: 'refunded',
} as const
