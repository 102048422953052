// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileEmpathWhite = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
      <g filter="url(#prefix__a)">
        <path
          fill="#fff"
          d="M19.297 36.234c-9.047 0-16.5-7.453-16.5-16.515 0-9.047 7.437-16.5 16.5-16.5 9.047 0 16.5 7.453 16.5 16.5 0 9.062-7.438 16.515-16.5 16.515ZM15.5 12.75c-2.594 0-4.594 2-4.594 4.906 0 4.766 5.375 8.516 7.453 9.813.282.156.688.375.953.375.266 0 .625-.219.907-.375 2.078-1.297 7.453-5.047 7.453-9.813 0-2.906-2-4.906-4.594-4.906-1.781 0-3.078 1.031-3.781 2.297-.719-1.266-2.016-2.297-3.797-2.297Z"
        />
      </g>
      <defs>
        <filter
          id="prefix__a"
          width={33}
          height={34.016}
          x={2.797}
          y={3.219}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1626_7627" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1626_7627" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
export { IconProfileEmpathWhite }
