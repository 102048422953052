/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Targetbodytypeenumrequest =
  (typeof Targetbodytypeenumrequest)[keyof typeof Targetbodytypeenumrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Targetbodytypeenumrequest = {
  regular: 'regular',
  fit: 'fit',
  flat_stomach: 'flat_stomach',
  athletic: 'athletic',
  curvy: 'curvy',
  cut: 'cut',
  shredded: 'shredded',
  bulk: 'bulk',
  extra_bulk: 'extra_bulk',
  muscle_man: 'muscle_man',
} as const
