import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'

// reducer
import { getClientData, saveClientData } from './helper'
import { INITIAL_STATE, reducer } from './reducer'

import { useRawFlowManager } from '../FlowManagerProvider'

import type { Action, Dispatch, State } from './types'

export const ClientDataStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

/**
 * Provides api to child components via hooks api.
 */
export const useClientData = () => {
  const context = React.useContext(ClientDataStateContext)

  if (!context) {
    throw new Error(`useClientData must be used within ClientDataProvider`)
  }

  return context
}

/**
 * ClientDataProvider component props
 */
interface ClientDataProviderProps {
  /**
   * The child nodes ClientDataProvider has wrapped
   */
  children?: React.ReactNode
}

export const ClientDataProvider = ({ children }: ClientDataProviderProps) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE, () => getClientData(INITIAL_STATE))

  const tempState = useRef(state)

  const modifiedDispatch = useCallback((action: Action) => {
    tempState.current = reducer(tempState.current, action)
    dispatch(action)
  }, [])

  const {
    operations: { filterOnboardingFlowByState, registerCallbackBeforeOpenNextPage },
  } = useRawFlowManager() || {
    operations: {
      filterOnboardingFlowByState: () => {},
      registerCallbackBeforeOpenNextPage: () => {},
    },
  }

  const callbackBeforeOpenNextPage = React.useCallback(() => {
    filterOnboardingFlowByState(tempState.current)
  }, [filterOnboardingFlowByState])

  useEffect(() => {
    registerCallbackBeforeOpenNextPage(callbackBeforeOpenNextPage)
  }, [callbackBeforeOpenNextPage, registerCallbackBeforeOpenNextPage])

  useEffect(() => saveClientData(state), [state])

  const value = useMemo(() => ({ state, dispatch: modifiedDispatch }), [state, modifiedDispatch])

  return <ClientDataStateContext.Provider value={value}>{children}</ClientDataStateContext.Provider>
}
