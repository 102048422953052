export const FLOW_MANAGER_COOKIE_NAME_PREFIX =
  process.env.NEXT_PUBLIC_FLOW_MANAGER_COOKIE_NAME_PREFIX || 'fm'

export const ONBOARDING_FINISHED_COOKIE_NAME = `${FLOW_MANAGER_COOKIE_NAME_PREFIX}${
  process.env.NEXT_PUBLIC_ONBOARDING_FINISHED_COOKIE_NAME || 'onb_end'
}`

export const ONBOARDING_FINISHED_COOKIE_EXPIRATION_IN_HOURS =
  Number(process.env.NEXT_PUBLIC_ONBOARDING_FINISHED_COOKIE_EXPIRATION_IN_HOURS) || 24

export const DISCOUNT_DURATION_COOKIE_NAME_PREFIX = `${FLOW_MANAGER_COOKIE_NAME_PREFIX}tm_`

export const UPSELL_BOUGHT_COOKIE_NAME = `${FLOW_MANAGER_COOKIE_NAME_PREFIX}_upsell_bought`
