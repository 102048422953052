import { isExceptionLogFnParams, TransportFn } from 'libs/logger'

export const logCache: string[] = []

export const transport: TransportFn = (params) => {
  const { level, methodFnArgs } = params

  let consoleArgs = [...methodFnArgs]

  if (isExceptionLogFnParams(params)) {
    const { error } = params
    consoleArgs = error.cause
      ? ['', error, `Caused by:`, error.cause, ...consoleArgs]
      : [error, ...consoleArgs]
  }

  const argsString = consoleArgs
    .map((arg) => {
      if (typeof arg === 'object') {
        return JSON.stringify(arg)
      }

      return arg
    })
    .join(' ')
  logCache.push(`${new Date().toISOString()} | ${level.toUpperCase()} | ${argsString}`)
}
