import { getSessionStorageValue, setSessionStorageValue } from 'shared/utils/sessionStorage'

import { UnitsSystem } from './constants/UnitsSystem'
import { WeightUnit } from './constants/WeightUnit'
import { State } from './types'

const CLIENT_DATA_KEY = 'clientData'

export const saveClientData = (value: State) => {
  setSessionStorageValue(CLIENT_DATA_KEY, JSON.stringify(value))
}

export const getClientData = (defaultValue: any) => {
  const data = getSessionStorageValue(CLIENT_DATA_KEY)

  if (!data) {
    return defaultValue
  }

  try {
    const parsedData = JSON.parse(data)

    if (parsedData?.occasionDate) {
      parsedData.occasionDate = new Date(parsedData.occasionDate)
    }

    return parsedData
  } catch (e) {
    return defaultValue
  }
}

export const getUnitsSystemByWeightUnit = (unit: WeightUnit) => {
  if (unit === WeightUnit.KG) {
    return UnitsSystem.METRIC
  }

  return UnitsSystem.IMPERIAL
}
