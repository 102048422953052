// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileHarmoniserWhite = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
      <g filter="url(#prefix__a)">
        <path
          fill="#fff"
          d="M19.297 36.234c-9.047 0-16.5-7.453-16.5-16.515 0-9.047 7.437-16.5 16.5-16.5 9.047 0 16.5 7.453 16.5 16.5 0 9.062-7.438 16.515-16.5 16.515Zm0-29h-.031.03Zm6.219 18.735c0 3.062-2.188 5.468-4.891 6.078a12.363 12.363 0 0 0 11.063-12.328 12.37 12.37 0 0 0-12.391-12.39c-.531 0-1.063.03-1.563.093-2.718.703-4.703 3.14-4.703 6.047 0 2.75 2.422 5.156 6.266 6.25 3.828 1.062 6.219 3.453 6.219 6.25Z"
        />
      </g>
      <defs>
        <filter
          id="prefix__a"
          width={33}
          height={34.016}
          x={2.797}
          y={3.219}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1626_7667" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1626_7667" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
export { IconProfileHarmoniserWhite }
