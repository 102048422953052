import React from 'react'

import { themes } from 'ui-elements/themes/themes'

import { useFlowLoader } from 'shared/providers/FlowLoader'
import { useTheme, DEFAULT_THEME_NAME } from 'shared/providers/ThemeProvider'

export function withTheme<P>(WrappedComponent: React.ComponentType<P>) {
  return (props: P) => {
    const {
      operations: { setTheme },
    } = useTheme()

    const {
      models: { config },
    } = useFlowLoader()

    React.useEffect(() => {
      if (config) {
        const { flow_name } = config

        for (const theme of Object.values(themes)) {
          if (flow_name.endsWith(theme.name)) {
            return setTheme(theme.name)
          }

          setTheme(DEFAULT_THEME_NAME)
        }
      }
    }, [config, setTheme])

    // @ts-expect-error
    return <WrappedComponent {...props} />
  }
}
