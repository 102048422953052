// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileRegulatorWhite = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
      <g filter="url(#prefix__a)">
        <path
          fill="#fff"
          d="M19.297 36.234c-9.047 0-16.5-7.453-16.5-16.515 0-9.047 7.437-16.5 16.5-16.5 9.047 0 16.5 7.453 16.5 16.5 0 9.062-7.438 16.515-16.5 16.515Zm0-4.109c6.875 0 12.39-5.531 12.39-12.406a12.37 12.37 0 0 0-12.39-12.39 12.347 12.347 0 0 0-12.375 12.39c0 6.875 5.516 12.406 12.375 12.406Zm0-8.469c-1.578 0-2.766-1.187-2.766-2.765 0-.266.078-.766.203-1.328l1.532-7.704c.125-.593.53-.906 1.03-.906.517 0 .907.313 1.032.906l1.547 7.704c.11.562.203 1.062.203 1.328 0 1.578-1.203 2.765-2.781 2.765Zm-5.89-8.11a1.704 1.704 0 0 1-1.704-1.687c0-.937.766-1.703 1.703-1.703.922 0 1.688.766 1.688 1.703a1.7 1.7 0 0 1-1.688 1.688Zm11.796 0A1.704 1.704 0 0 1 23.5 13.86c0-.937.766-1.703 1.703-1.703.922 0 1.688.766 1.688 1.703a1.7 1.7 0 0 1-1.688 1.688Zm-14.312 5.86a1.7 1.7 0 0 1-1.688-1.687c0-.938.766-1.703 1.688-1.703.937 0 1.703.765 1.703 1.703 0 .922-.766 1.687-1.703 1.687Zm16.812 0a1.69 1.69 0 0 1-1.687-1.687c0-.938.75-1.703 1.687-1.703.938 0 1.688.765 1.688 1.703a1.69 1.69 0 0 1-1.688 1.687Zm-14.297 5.86c-.937 0-1.703-.75-1.703-1.688 0-.937.766-1.687 1.703-1.687.922 0 1.688.75 1.688 1.687a1.69 1.69 0 0 1-1.688 1.688Zm11.797 0c-.937 0-1.703-.75-1.703-1.688 0-.937.766-1.687 1.703-1.687.922 0 1.688.75 1.688 1.687a1.69 1.69 0 0 1-1.688 1.688Z"
        />
      </g>
      <defs>
        <filter
          id="prefix__a"
          width={33}
          height={34.016}
          x={2.797}
          y={3.219}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1626_7567" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1626_7567" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
export { IconProfileRegulatorWhite }
