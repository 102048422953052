/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Debqfrequencyresponse =
  (typeof Debqfrequencyresponse)[keyof typeof Debqfrequencyresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Debqfrequencyresponse = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
} as const
