import { useEffect } from 'react'

import { isE2EBrowser } from 'shared/utils/env'

export const useSkipAnimationInE2E = () => {
  useEffect(() => {
    import('@react-spring/web').then(({ Globals }) => {
      Globals.assign({
        skipAnimation: isE2EBrowser(),
      })

      window.document.body.setAttribute('data-animation-ready', 'true')
    })
  }, [])
}
