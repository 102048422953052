import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  'common.eatingProfile.regulator': {
    defaultMessage: 'Regulator',
    id: 'fjzxNo',
    description: 'Eating profile name',
  },
  'common.eatingProfile.guardian': {
    defaultMessage: 'Guardian',
    id: 'SbaaoC',
    description: 'Eating profile name',
  },
  'common.eatingProfile.explorer': {
    defaultMessage: 'Explorer',
    id: 'Q/+NW0',
    description: 'Eating profile name',
  },
  'common.eatingProfile.stoic': {
    defaultMessage: 'Stoic',
    id: 'fqpALY',
    description: 'Eating profile name',
  },
  'common.eatingProfile.pacifier': {
    defaultMessage: 'Comfort Seeker',
    id: 'Belahu',
    description: 'Eating profile name',
  },
  'common.eatingProfile.empath': {
    defaultMessage: 'Empath',
    id: 'RIHOTy',
    description: 'Eating profile name',
  },
  'common.eatingProfile.enthusiast': {
    defaultMessage: 'Enthusiast',
    id: 'khhczk',
    description: 'Eating profile name',
  },
  'common.eatingProfile.harmonizer': {
    defaultMessage: 'Harmonizer',
    id: 'EBblaY',
    description: 'Eating profile name',
  },
  'common.eatingProfile.regulator.description': {
    defaultMessage:
      'Regulators like things neat and organized, but sometimes life can be messy and throw you off. Our easy steps, like paying attention to your meals and being kind to yourself, will help you find calm in the mess. This way, you can enjoy your food more and turn tough times into your strong points, helping you lose weight successfully.',
    id: 'wJUwvs',
    description: 'Eating profile short description',
  },
  'common.eatingProfile.guardian.description': {
    defaultMessage:
      'Guardians have strong standards and usually stick to their eating habits. But emotions can sometimes make them turn to comfort food. Our app gives simple tasks like being kind to yourself and handling emotions better, which can help Guardians build a healthier relationship with food. This way, they can handle emotional eating better and have a more balanced approach to food that matches their strong values, helping them succeed in losing weight.',
    id: 'gGdXCi',
    description: 'Eating profile short description',
  },
  'common.eatingProfile.explorer.description': {
    defaultMessage:
      'Explorers are disciplined eaters who love trying new foods, but social pressures and temptations can be challenging. Our app offers tools like mindfulness, setting limits, and flexible thinking to help Explorers enjoy their food adventures while staying healthy. Use these tools to make your food journey successful and healthy.',
    id: '2ESTDk',
    description: 'Eating profile short description',
  },
  'common.eatingProfile.stoic.description': {
    defaultMessage:
      'Stoics are good at sticking to their diets and saying no to temptations. But sometimes, they miss out on enjoying their food. Our app has tools like mindful eating and smart snacking to help Stoics find a good balance. This way, they can enjoy their meals more while still reaching their weight loss goals.',
    id: 'nWY4/g',
    description: 'Eating profile short description',
  },
  'common.eatingProfile.pacifier.description': {
    defaultMessage:
      'Comfort Seekers may use food for comfort and sometimes overeat because of emotions or social situations. Our app offers simple steps like mindful eating, leaning on friends, and wellness activities to help Comfort Seekers balance emotions and eating habits. These steps can create a better relationship between feelings and food choices, supporting a healthy lifestyle and weight loss goals. Say goodbye to struggles and hello to balanced, satisfying eating.',
    id: 'zl6gq+',
    description: 'Eating profile short description',
  },
  'common.eatingProfile.empath.description': {
    defaultMessage:
      "Empaths love food and aren't swayed by ads or others. But, their emotional connection can sometimes lead to imbalance. Our app offers tools like exploring emotions, mindful eating, and being kind to oneself to help Empaths manage their emotional ties to food. This way, they can have a balanced, happy relationship with food, enjoy meals, and have a successful wellness journey. Say goodbye to struggles and hello to a nourishing relationship with food that's good for both body and soul.",
    id: 'Uby+fu',
    description: 'Eating profile short description',
  },
  'common.eatingProfile.enthusiast.description': {
    defaultMessage:
      'Enthusiasts love a variety of foods and often choose based on looks or friends, which can lead to unhealthy options. Our app has strategies like mindful eating, smart choices, and being kind to yourself to help Enthusiasts balance their love of food with healthy decisions. This way, they can enjoy food while also taking care of their health. Say goodbye to food dilemmas and hello to a balanced relationship with food that brings both joy and wellness, helping in their weight loss journey.',
    id: 'rAPL+B',
    description: 'Eating profile short description',
  },
  'common.eatingProfile.harmonizer.description': {
    defaultMessage:
      'Harmonizers enjoy eating without feeling swayed by emotions or peer choices. They indulge without guilt but may sometimes overeat or feel out of place at social meals. Our app offers simple tasks like eating attentively, recognizing true hunger, and connecting with others during meals to help Harmonizers stay in tune with their bodies and enjoy every bite. Start your journey to tackle small problems and create a balanced and happy relationship with food.',
    id: 'iiN26W',
    description: 'Eating profile short description',
  },
})
