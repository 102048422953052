/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Genderrequest = (typeof Genderrequest)[keyof typeof Genderrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Genderrequest = {
  male: 'male',
  female: 'female',
  other: 'other',
} as const
