/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Platformenumresponse = (typeof Platformenumresponse)[keyof typeof Platformenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Platformenumresponse = {
  ios: 'ios',
  web: 'web',
  android: 'android',
} as const
