/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Occasionresponse = (typeof Occasionresponse)[keyof typeof Occasionresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Occasionresponse = {
  vacation: 'vacation',
  wedding: 'wedding',
  holiday: 'holiday',
  summer: 'summer',
  reunion: 'reunion',
  birthday: 'birthday',
  none: 'none',
} as const
