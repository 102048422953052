/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Eatinghabitsresponse = (typeof Eatinghabitsresponse)[keyof typeof Eatinghabitsresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Eatinghabitsresponse = {
  balanced_and_healthy: 'balanced_and_healthy',
  healthy_and_indulgent: 'healthy_and_indulgent',
  indulgent_and_unhealthy: 'indulgent_and_unhealthy',
} as const
