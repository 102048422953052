/**
 * Rounds up number to number with certain decimal places
 * @param value number
 * @param places amount of decimal places
 * @returns rounded number
 */
export const roundTo = (value: number, places?: number) => {
  if (typeof places === 'undefined') {
    return value
  }

  return Number(Math.round(Number(value + `e+${places}`)) + `e-${places}`)
}

export const toInteger = (value: unknown) => {
  if (value === null || value === true || value === false) {
    return NaN
  }

  const number = Number(value)

  if (isNaN(number)) {
    return number
  }

  return number < 0 ? Math.ceil(number) : Math.floor(number)
}
