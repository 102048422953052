import axios from 'axios'
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry'
import React from 'react'

import { isAxiosError } from 'shared/utils/api'
import { Cache } from 'shared/utils/cache'

const DEFAULT_OPTION_RETRY = 5

const DEFAULT_OPTIONS: Required<UseLoadJsonFileOptions<unknown>> = {
  enabled: true,
  retry: DEFAULT_OPTION_RETRY,
  retryStrategy: 'default',
  useErrorBoundary: false,
  retryCondition: () => false,
  onSuccess: () => {},
  onError: () => {},
}

const enum LoadStatus {
  INITIAL = 'initial',
  LOADING = 'loading',
  ENABLED = 'enabled',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface UseLoadJsonFileOptions<T> {
  enabled?: boolean
  retry?: number
  retryStrategy?: 'default' | 'exponentialDelay'
  useErrorBoundary?: boolean
  retryCondition?: IAxiosRetryConfig['retryCondition']
  onSuccess?: (data: T) => void
  onError?: (error: Error) => void
}

const cache = new Cache()
const axiosInstance = axios.create()

// add interceptor once to axios instance
axiosRetry(axiosInstance)

const isRequestCancelledError = (error: unknown) => {
  if (isAxiosError(error)) {
    return error.code === 'ERR_CANCELED'
  }

  return false
}

export const useLoadJsonFile = <TData extends unknown>(
  path: string,
  options?: UseLoadJsonFileOptions<TData>
) => {
  const [error, setError] = React.useState<Error | null>(null)
  const [data, setData] = React.useState<TData | undefined>(undefined)
  const [status, setStatus] = React.useState<LoadStatus>(LoadStatus.INITIAL)
  const { enabled, retry, retryStrategy, useErrorBoundary, onSuccess, onError, retryCondition } = {
    ...DEFAULT_OPTIONS,
    ...options,
  }
  const optionsRef = React.useRef({
    retry,
    useErrorBoundary,
    retryStrategy,
    onSuccess,
    onError,
    retryCondition,
  })

  React.useEffect(() => {
    optionsRef.current = {
      retry,
      useErrorBoundary,
      retryStrategy,
      onSuccess,
      onError,
      retryCondition,
    }
  })

  React.useEffect(() => {
    if (!enabled) {
      setStatus(LoadStatus.ENABLED)

      return
    }

    // try to read data from cache
    const cachedData = cache.get<TData>(path)

    if (cachedData) {
      setData(cachedData)

      return
    }

    // load data if cache doesn't have it
    setStatus(LoadStatus.LOADING)

    const controller = new AbortController()

    axiosInstance
      .get<TData>(path, {
        signal: controller.signal,
        'axios-retry': {
          retries: optionsRef.current.retry,
          retryDelay:
            optionsRef.current.retryStrategy === 'exponentialDelay'
              ? axiosRetry.exponentialDelay
              : undefined,
          retryCondition: optionsRef.current.retryCondition,
        },
      })
      .then(({ data }) => {
        setData(data)
        cache.set<TData>(path, data)

        setStatus(LoadStatus.SUCCESS)
        optionsRef.current.onSuccess(data)
      })
      .catch((error) => {
        setError(error)
        if (!axios.isCancel(error)) {
          setStatus(LoadStatus.ERROR)
          optionsRef.current.onError(error)
        }
      })

    return () => {
      controller.abort()
    }
  }, [enabled, path])

  React.useEffect(() => {
    if (optionsRef.current.useErrorBoundary && error && !isRequestCancelledError(error)) {
      throw new Error('Loading error JSON: useLoadJsonFile', { cause: error })
    }
  }, [error])

  const api = React.useMemo(
    () => ({
      data,
      error,
      isLoading: status === LoadStatus.LOADING,
      isError: status === LoadStatus.ERROR,
      isSuccess: status === LoadStatus.SUCCESS,
    }),
    [data, status, error]
  )

  return api
}
