/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Preferredmealrequest = (typeof Preferredmealrequest)[keyof typeof Preferredmealrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Preferredmealrequest = {
  home_cooked: 'home_cooked',
  fast_food: 'fast_food',
  restaurant_dining: 'restaurant_dining',
  snacks: 'snacks',
} as const
