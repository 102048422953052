/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Surveyquestiontypeenumresponse =
  (typeof Surveyquestiontypeenumresponse)[keyof typeof Surveyquestiontypeenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Surveyquestiontypeenumresponse = {
  single_select: 'single_select',
  multi_select: 'multi_select',
  slider: 'slider',
  text_input: 'text_input',
} as const
