import { getQueryValueByKey } from 'shared/utils/common'

const DEVTOOLS_KEY_NAME = 'omo_devtools'
const DEFAULT_DEVTOOLS_VALUE = process.env.NEXT_PUBLIC_APP_DEVTOOLS_DEFAULT_VISIBILITY

const getIsDevtoolsAvailable = () => {
  const queryValue = getQueryValueByKey(DEVTOOLS_KEY_NAME)
  const storageValue = global?.window?.localStorage?.getItem(DEVTOOLS_KEY_NAME)

  const value = queryValue || storageValue || DEFAULT_DEVTOOLS_VALUE

  if (queryValue && storageValue !== queryValue) {
    global?.window?.localStorage?.setItem(DEVTOOLS_KEY_NAME, queryValue)
  }

  return value === 'true'
}

export const IS_DEVTOOLS_AVAILABLE = getIsDevtoolsAvailable()
