/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Actiontypeenumrequest =
  (typeof Actiontypeenumrequest)[keyof typeof Actiontypeenumrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Actiontypeenumrequest = {
  finish_scenario: 'finish_scenario',
  set_next_card_id: 'set_next_card_id',
} as const
