/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Weightlossfocusresponse =
  (typeof Weightlossfocusresponse)[keyof typeof Weightlossfocusresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Weightlossfocusresponse = {
  healthy_habits: 'healthy_habits',
  nutrition: 'nutrition',
  activity: 'activity',
  other: 'other',
} as const
