import { useCallback } from 'react'

import { ScreenId } from 'shared/config/types'
import { useAuthToken } from 'shared/providers/AuthTokenProvider'
import { logger } from 'shared/utils/logger'

import { usePreparePaymentFlow } from './usePreparePaymentFlow'
import { useRedirectToPage } from './useRedirectToPage'
import { useSendOnboardingEndEvent } from './useSendOnboardingEndEvent'

import { FlowManager } from '../helpers/FlowManager'

type UseOpenNextPageArgs = {
  flowManager: FlowManager | null
  currentScreenId: ScreenId
}

export const useSkipToPaymentPage = ({ flowManager, currentScreenId }: UseOpenNextPageArgs) => {
  const preparePaymentFlow = usePreparePaymentFlow()
  const sendOnboardingEndEvent = useSendOnboardingEndEvent()
  const redirectToPage = useRedirectToPage()
  const { token } = useAuthToken()

  const isUserCreated = Boolean(token)

  return useCallback(
    (delay?: number) => {
      if (!isUserCreated) {
        logger.error(new Error('Attempt to access payment page without created user'))

        return
      }

      const initialPaymentScreen = flowManager?.getInitialPaymentScreen()
      const { id: nextScreenId } = initialPaymentScreen ?? {}
      const nextScreenUrl = nextScreenId ? flowManager?.getScreenUrlById(nextScreenId) : ''

      sendOnboardingEndEvent()

      if (!nextScreenUrl) return

      nextScreenId && flowManager?.saveLastScreenId(nextScreenId)

      if (nextScreenId === initialPaymentScreen?.id) {
        preparePaymentFlow()
      }

      redirectToPage({ delay, nextScreenUrl, currentScreenId })
    },
    [
      currentScreenId,
      flowManager,
      isUserCreated,
      preparePaymentFlow,
      redirectToPage,
      sendOnboardingEndEvent,
    ]
  )
}
