/**
 * Layout breakpoints in theme
 */
export const breakpoints = {
  xxs: 320,
  xs: 375,
  s: 503,
  m: 768,
  l: 1024,
  xl: 1440,
}
