export const AB_TEST_COOKIE_NAME = process.env.NEXT_PUBLIC_AB_TEST_COOKIE_NAME || 'frr_ab_test'
export const AB_TEST_COOKIE_EXPIRATION_IN_HOURS =
  process.env.NEXT_PUBLIC_AB_TEST_COOKIE_EXPIRATION_IN_HOURS || '24'

export const DEFAULT_BRANCH = 'default'
export const DEFAULT_EXPERIMENT = 'default'
export const DEFAULT_VARIANT = 'default'

export enum AB_TEST_METADATA_KEY {
  BRANCH = 'branch-name',
  EXPERIMENT = 'test-name',
  VARIANT = 'variant-name',
}

export enum AB_TEST_VARIABLE_KEY {
  GENDER = 'gender',
}

export const EMPTY_META_VALUE: Record<AB_TEST_METADATA_KEY, string> = {
  [AB_TEST_METADATA_KEY.BRANCH]: '',
  [AB_TEST_METADATA_KEY.EXPERIMENT]: '',
  [AB_TEST_METADATA_KEY.VARIANT]: '',
}

export const URL_PARAMS_COOKIE_NAME = 'frr__urlParams'
export const AB_TEST_NAME_COOKIE_NAME = 'frr__abTestName'
export const UUID_COOKIE_NAME = 'frr__uuid'
export const COUNTRY_COOKIE_NAME = 'frr_country'
