import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { ThemeName, Theme, themes } from 'ui-elements/themes/themes'

export const DEFAULT_THEME_NAME: ThemeName = 'default'

interface ThemeContextInterface {
  models: {
    /**
     * Current theme
     */
    theme: Theme<ThemeName>
    themeName: ThemeName
  }
  operations: {
    /**
     * Method to change current theme
     */
    setTheme: (theme: ThemeName) => void
  }
}

/**
 * Contains theme api
 */
const ThemeContext = React.createContext<ThemeContextInterface | null>(null)

/**
 * ```ts
 * const {
 *   models: {
 *     theme,
 *   },
 *   operations: {
 *     setTheme,
 *   }
 * } = useTheme();
 * ```
 *
 * Provides theme api to child components via hooks api.
 */
function useTheme() {
  const context = React.useContext(ThemeContext)

  if (!context) {
    throw new Error(`useTheme must be used within ThemeProvider`)
  }

  return context
}

interface ThemeProviderProps {
  /**
   * The child nodes ThemeProvider has wrapped
   */
  children?: React.ReactNode
  /**
   * Default theme
   * @default 'default'
   */
  defaultTheme?: ThemeName
}

/**
 * ```tsx
 * <ThemeProvider defaultTheme="default">
 *   {children}
 * </ThemeProvider>
 * ```
 *
 * Provides theme api to its child components via context api.
 */
const ThemeProvider = ({
  defaultTheme = DEFAULT_THEME_NAME,
  children,
}: ThemeProviderProps): JSX.Element => {
  const [themeName, setThemeName] = React.useState(defaultTheme)

  const operations = React.useMemo<ThemeContextInterface['operations']>(
    () => ({
      setTheme: (nextTheme) => {
        window.document.body.setAttribute('data-theme', nextTheme)
        setThemeName(nextTheme)
      },
    }),
    []
  )

  // provide theme api to components
  const api = React.useMemo<ThemeContextInterface>(
    () => ({
      models: {
        theme: themes[themeName],
        themeName,
      },
      operations,
    }),
    [themeName, operations]
  )

  return (
    <ThemeContext.Provider value={api}>
      <StyledThemeProvider theme={api.models.theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, useTheme }
