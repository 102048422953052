/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Currentbodytypeenumrequest =
  (typeof Currentbodytypeenumrequest)[keyof typeof Currentbodytypeenumrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Currentbodytypeenumrequest = {
  regular: 'regular',
  flabby: 'flabby',
  extra: 'extra',
  overweight: 'overweight',
  obese: 'obese',
} as const
