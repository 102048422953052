import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useState, useEffect, createContext, useContext, useMemo, useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useLoadJsonFile } from 'shared/hooks/useLoadJsonFile'

import {
  FlowConfig,
  useGetGetBranchConfigBranch,
  useGetGetExperimentConfigBranchExperiment,
} from 'shared/api/testania'
import {
  getAbTestConfig,
  getAbTestVariantFromFlowName,
  isTestaniaBranchEnabled,
  isTestaniaExperimentEnabled,
  saveABTestToCookie,
} from 'shared/providers/FlowLoader/helpers'
import { logger } from 'shared/utils/logger'

import {
  AB_TEST_COOKIE_NAME,
  AB_TEST_METADATA_KEY,
  DEFAULT_BRANCH,
  DEFAULT_EXPERIMENT,
  DEFAULT_VARIANT,
  EMPTY_META_VALUE,
} from './constants'
import { ABTestMetadata, ABTestVariables } from './types'

export const FALLBACK_CONFIG_PATH = process.env.NEXT_PUBLIC_FALLBACK_CONFIG_FILE_PATH

if (!FALLBACK_CONFIG_PATH) {
  throw new Error('NEXT_PUBLIC_FALLBACK_CONFIG_FILE_PATH is required')
}

interface FlowLoaderContextInterface {
  models: {
    config: FlowConfig | null
    flowName?: string
    abTestVariables: ABTestVariables | null
    isNewVisitor: boolean
    loading: boolean
    abTestMetadata: ABTestMetadata | null
  }
  operations: {
    resetABTest(): void
  }
}

export const FlowLoaderContext = createContext<FlowLoaderContextInterface | null>(null)

function useFlowLoader() {
  const context = useContext(FlowLoaderContext)

  if (!context) {
    throw new Error(`useFlowLoader must be used within FlowLoader`)
  }

  return context
}

interface FlowLoaderProps {
  children?: React.ReactNode
  onLoad?: (config: FlowConfig, country?: string, locale?: string) => FlowConfig
}

const FlowLoader = ({ children, onLoad }: FlowLoaderProps): JSX.Element => {
  const [config, setConfig] = useState<FlowConfig | null>(null)
  const [country, setCountry] = useState<string | undefined>(undefined)
  const [abTestMetadata, setAbTestMetadata] = useState<ABTestMetadata>({
    ...EMPTY_META_VALUE,
  })
  const [abTestVariables, setAbTestVariables] = useState<ABTestVariables | null>(null)
  const [isNewVisitor, setIsNewVisitor] = useState(false)
  const [isTestaniaFailed, setIsTestaniaFailed] = useState(false)
  const { locale } = useIntl()
  const [hasPreloaded, setHasPreloaded] = useState(true)

  useEffect(() => {
    const { isNewVisitor, abTest, metadata } = getAbTestConfig()

    setIsNewVisitor(isNewVisitor)
    setAbTestMetadata(metadata)
    setAbTestVariables(abTest.variables)
  }, [])

  useEffect(() => {
    if (isNewVisitor && config && abTestMetadata && abTestVariables) {
      const variant = getAbTestVariantFromFlowName(config.flow_name)
      saveABTestToCookie(
        { ...abTestMetadata, [AB_TEST_METADATA_KEY.VARIANT]: variant },
        abTestVariables
      )
    }
  }, [config, isNewVisitor, abTestMetadata, abTestVariables])

  useEffect(() => {
    if (window.testaniaPromise) {
      window.testaniaPromise
        .then(({ data, country }) => {
          setConfig(data)
          setCountry(country)
        })
        .catch((err: any) => {
          logger.error(
            new Error('Testania preload failed', {
              cause: err,
            }),
            {
              tags: { hasFetch: !!global.window.fetch },
            }
          )
          setHasPreloaded(false)
        })
    } else {
      setHasPreloaded(false)
    }
  }, [])

  const { isLoading: isExperimentConfigLoading } = useGetGetExperimentConfigBranchExperiment(
    abTestMetadata[AB_TEST_METADATA_KEY.BRANCH],
    abTestMetadata[AB_TEST_METADATA_KEY.EXPERIMENT],
    { 'variant-name': abTestMetadata[AB_TEST_METADATA_KEY.VARIANT] },
    {
      query: {
        cacheTime: Infinity,
        retry: 0,
        enabled: !config && isTestaniaExperimentEnabled(abTestMetadata) && !hasPreloaded,
        onError: (error) => {
          const status = error.response?.status || 500

          // service is unavailable or request for default branch config is failed or not found
          if (status >= 500 || abTestMetadata[AB_TEST_METADATA_KEY.BRANCH] === DEFAULT_BRANCH) {
            // use fallback config
            setIsTestaniaFailed(true)
            setAbTestMetadata({ ...EMPTY_META_VALUE })
          } else {
            setAbTestMetadata({
              [AB_TEST_METADATA_KEY.BRANCH]: abTestMetadata[AB_TEST_METADATA_KEY.BRANCH],
              [AB_TEST_METADATA_KEY.EXPERIMENT]: '',
              [AB_TEST_METADATA_KEY.VARIANT]: '',
            })
          }

          logger.debug('Request for experiment config failed', {
            url: error.config.url,
            status,
            code: error.code,
          })
        },
        onSuccess: ({ data: config, headers }) => {
          if (config) {
            setConfig(config)
            setCountry(headers.country)
          } else {
            setAbTestMetadata({
              [AB_TEST_METADATA_KEY.BRANCH]: abTestMetadata[AB_TEST_METADATA_KEY.BRANCH],
              [AB_TEST_METADATA_KEY.EXPERIMENT]: '',
              [AB_TEST_METADATA_KEY.VARIANT]: '',
            })
          }
        },
      },
    }
  )

  const { isLoading: isBranchConfigLoading } = useGetGetBranchConfigBranch(
    abTestMetadata[AB_TEST_METADATA_KEY.BRANCH],
    {
      query: {
        cacheTime: Infinity,
        retry: 0,
        enabled: !config && isTestaniaBranchEnabled(abTestMetadata) && !hasPreloaded,
        onError: (error) => {
          const status = error.response?.status || 500

          if (status >= 500) {
            // use fallback config
            setIsTestaniaFailed(true)
            setAbTestMetadata({ ...EMPTY_META_VALUE })
          } else {
            setAbTestMetadata({
              [AB_TEST_METADATA_KEY.BRANCH]: DEFAULT_BRANCH,
              [AB_TEST_METADATA_KEY.EXPERIMENT]: DEFAULT_EXPERIMENT,
              [AB_TEST_METADATA_KEY.VARIANT]: DEFAULT_VARIANT,
            })
          }

          logger.debug('Request for branch config failed', {
            url: error.config.url,
            status,
            code: error.code,
          })
        },
        onSuccess: ({ data: config, headers }) => {
          if (config) {
            setConfig(config)
            setCountry(headers.country)
          } else {
            setAbTestMetadata({
              [AB_TEST_METADATA_KEY.BRANCH]: DEFAULT_BRANCH,
              [AB_TEST_METADATA_KEY.EXPERIMENT]: DEFAULT_EXPERIMENT,
              [AB_TEST_METADATA_KEY.VARIANT]: DEFAULT_VARIANT,
            })
          }
        },
      },
    }
  )

  const isFallbackEnabled = Boolean(
    isTestaniaFailed &&
      !config &&
      !hasPreloaded &&
      !abTestMetadata[AB_TEST_METADATA_KEY.BRANCH] &&
      !abTestMetadata[AB_TEST_METADATA_KEY.EXPERIMENT] &&
      !abTestMetadata[AB_TEST_METADATA_KEY.VARIANT]
  )

  const { isLoading: isDefaultConfigLoading } = useLoadJsonFile<FlowConfig>(FALLBACK_CONFIG_PATH, {
    enabled: isFallbackEnabled,
    retryStrategy: 'exponentialDelay',
    useErrorBoundary: isFallbackEnabled,
    retryCondition: (error) => axios.isAxiosError(error),
    onSuccess: (fallbackConfig) => {
      if (fallbackConfig) {
        setConfig(fallbackConfig)

        setAbTestMetadata({
          [AB_TEST_METADATA_KEY.BRANCH]: DEFAULT_BRANCH,
          [AB_TEST_METADATA_KEY.EXPERIMENT]: DEFAULT_EXPERIMENT,
          [AB_TEST_METADATA_KEY.VARIANT]: DEFAULT_VARIANT,
        })
      }
    },
    onError: (error) => {
      logger.error(Error(`Onboarding.start.error`, { cause: error }), {
        tags: {
          'app.feature': 'onboarding',
          'app.component': 'flow-loader',
        },
      })
    },
  })

  const resetABTest = useCallback(() => {
    Cookies.remove(AB_TEST_COOKIE_NAME)
  }, [])

  const parsedConfig = useMemo(() => {
    if (config) {
      return onLoad ? onLoad(config, country, locale) : config
    }

    return null
  }, [config, country, locale, onLoad])

  const api = useMemo<FlowLoaderContextInterface>(
    () => ({
      models: {
        config,
        flowName: parsedConfig?.flow_name,
        abTestVariables,
        isNewVisitor,
        loading: isBranchConfigLoading || isExperimentConfigLoading || isDefaultConfigLoading,
        abTestMetadata,
      },
      operations: {
        resetABTest,
      },
    }),
    [
      config,
      parsedConfig?.flow_name,
      abTestVariables,
      isNewVisitor,
      isBranchConfigLoading,
      isExperimentConfigLoading,
      isDefaultConfigLoading,
      abTestMetadata,
      resetABTest,
    ]
  )

  return <FlowLoaderContext.Provider value={api}>{children}</FlowLoaderContext.Provider>
}

export { FlowLoader, useFlowLoader }
