/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Conditionoperationenumresponse =
  (typeof Conditionoperationenumresponse)[keyof typeof Conditionoperationenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Conditionoperationenumresponse = {
  '=': '=',
  '!=': '!=',
  '<': '<',
  '<=': '<=',
  '>': '>',
  '>=': '>=',
} as const
