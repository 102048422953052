import Cookies from 'js-cookie'

import {
  AB_TEST_METADATA_KEY,
  AB_TEST_VARIABLE_KEY,
  AB_TEST_COOKIE_NAME,
  AB_TEST_COOKIE_EXPIRATION_IN_HOURS,
  DEFAULT_BRANCH,
} from 'shared/providers/FlowLoader/constants'
import { ABTest, ABTestMetadata, ABTestVariables } from 'shared/providers/FlowLoader/types'
import { getCurrentTimestamp, addHours } from 'shared/utils/date'

const getABTest = (query: string): ABTest => {
  const queryObj = new URLSearchParams(query)

  const metadata = (
    Object.keys(AB_TEST_METADATA_KEY) as Array<keyof typeof AB_TEST_METADATA_KEY>
  ).reduce((acc, key) => {
    let value = queryObj.get(AB_TEST_METADATA_KEY[key]) || ''

    if (value === 'undefined') {
      value = ''
    }

    acc[AB_TEST_METADATA_KEY[key]] = value

    return acc
  }, {} as ABTestMetadata)

  const variables = (
    Object.keys(AB_TEST_VARIABLE_KEY) as Array<keyof typeof AB_TEST_VARIABLE_KEY>
  ).reduce((acc, key) => {
    acc[AB_TEST_VARIABLE_KEY[key]] = queryObj.get(AB_TEST_VARIABLE_KEY[key]) || ''

    return acc
  }, {} as ABTestVariables)

  return {
    metadata,
    variables,
  }
}

export const saveCookieValue = (key: string, value: string) => {
  const now = getCurrentTimestamp()
  const expirationDate = addHours(now, Number(AB_TEST_COOKIE_EXPIRATION_IN_HOURS))

  Cookies.set(key, value, {
    expires: expirationDate,
    sameSite: 'Lax',
    secure: global.location.protocol.includes('https'),
    path: '/',
  })
}

export const saveABTestToCookie = (metadata: ABTestMetadata, variables: ABTestVariables) => {
  const now = getCurrentTimestamp()
  const cookieValue = new URLSearchParams({
    ...metadata,
    ...variables,
    timestamp: String(now),
  })

  saveCookieValue(AB_TEST_COOKIE_NAME, cookieValue.toString())
}

/**
 * Checks wether the visitor is new
 * @param {string} branchFromQuery - branch from query
 * @param {string} branchFromCookie - branch from cookie
 * @returns {Boolean} is the visitor new or not
 */
const checkIsNewVisitor = (branchFromQuery: string, branchFromCookie: string) => {
  return branchFromCookie === '' || (branchFromQuery !== '' && branchFromQuery !== branchFromCookie)
}

const getAbTestBranch = (branch: string) => {
  return branch || DEFAULT_BRANCH
}

// Example: { flow_name: "stay_on_track__overflow__Over_Flow__Control" }
export const getAbTestVariantFromFlowName = (flowName: string) => {
  const [_productName, _branch, _experiment, ...rest] = flowName.split('__')

  const variant = rest[rest.length - 1]

  return variant
}

export const getAbTestConfig = () => {
  const abTestFromQuery = getABTest(global.window.location.search)
  const abTestFromCookie = getABTest(Cookies.get(AB_TEST_COOKIE_NAME) || '')
  const isNewVisitor = checkIsNewVisitor(
    abTestFromQuery.metadata[AB_TEST_METADATA_KEY.BRANCH],
    abTestFromCookie.metadata[AB_TEST_METADATA_KEY.BRANCH]
  )
  const abTest = isNewVisitor ? abTestFromQuery : abTestFromCookie
  const branch = getAbTestBranch(abTest.metadata[AB_TEST_METADATA_KEY.BRANCH])
  const experiment = abTest.metadata[AB_TEST_METADATA_KEY.EXPERIMENT]
  const variant = abTest.metadata[AB_TEST_METADATA_KEY.VARIANT]

  return {
    abTest,
    isNewVisitor,
    metadata: {
      [AB_TEST_METADATA_KEY.BRANCH]: branch,
      [AB_TEST_METADATA_KEY.EXPERIMENT]: experiment,
      [AB_TEST_METADATA_KEY.VARIANT]: variant,
    },
  }
}

const isDefaultExperimentInDefaultBranch = (
  metadata: ReturnType<typeof getAbTestConfig>['metadata']
) => {
  return (
    metadata[AB_TEST_METADATA_KEY.BRANCH] === 'default' &&
    metadata[AB_TEST_METADATA_KEY.EXPERIMENT] === 'default'
  )
}

// If we have branch-name=default&test-name=default
// - redirect to default flow in default branch without experiment
export const isTestaniaExperimentEnabled = (
  metadata: ReturnType<typeof getAbTestConfig>['metadata']
) => {
  return (
    Boolean(metadata[AB_TEST_METADATA_KEY.BRANCH] && metadata[AB_TEST_METADATA_KEY.EXPERIMENT]) &&
    !isDefaultExperimentInDefaultBranch(metadata)
  )
}

// If we have branch-name=default&test-name=default
// - redirect to default flow in default branch without experiment
export const isTestaniaBranchEnabled = (
  metadata: ReturnType<typeof getAbTestConfig>['metadata']
) => {
  return Boolean(
    metadata[AB_TEST_METADATA_KEY.BRANCH] &&
      (!metadata[AB_TEST_METADATA_KEY.EXPERIMENT] || isDefaultExperimentInDefaultBranch(metadata))
  )
}
