/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Unitsenumresponse = (typeof Unitsenumresponse)[keyof typeof Unitsenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Unitsenumresponse = {
  metric: 'metric',
  imperial: 'imperial',
} as const
