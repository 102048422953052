/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Iconresponse = (typeof Iconresponse)[keyof typeof Iconresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Iconresponse = {
  recordcircle: 'record.circle',
  flagfill: 'flag.fill',
  crosscirclefill: 'cross.circle.fill',
  boltsquarefill: 'bolt.square.fill',
} as const
