// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileEnthusiastWhite = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
      <g filter="url(#prefix__a)">
        <path
          fill="#fff"
          d="M19.297 36.25c-9.047 0-16.5-7.453-16.5-16.5 0-9.063 7.437-16.5 16.5-16.5 9.047 0 16.5 7.438 16.5 16.5 0 9.047-7.438 16.5-16.5 16.5Zm-1.578-18.938c.718.172 1.234.813 1.781.813.625 0 1.203-.875 2.156-.875 1 0 1.532.875 2.172.875.531 0 1.031-.656 1.797-.813l-2.484-3.218c-.86-1.11-2.094-1.11-2.97.015l-2.452 3.204Zm-7.516 7.344h2.469c-.547-.922-.516-2.172.25-3.172l3.687-4.797-.187-.234c-.828-.906-1.953-.797-2.766.219l-4.453 5.687c-.765.97-.265 2.297 1 2.297Zm4.844 0H28.28c1.438 0 1.844-1.422 1.094-2.406l-2.75-3.578c-.984-.813-1.578.5-2.813.5-1.187 0-1.625-.875-2.156-.875-.515 0-.968.875-2.156.875-1.234 0-1.828-1.313-2.813-.5l-2.75 3.578c-.75.984-.343 2.406 1.11 2.406Z"
        />
      </g>
      <defs>
        <filter
          id="prefix__a"
          width={33}
          height={34}
          x={2.797}
          y={3.25}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1626_7687" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1626_7687" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
export { IconProfileEnthusiastWhite }
