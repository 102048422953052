/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type EmojisingleselectcardrequestType =
  (typeof EmojisingleselectcardrequestType)[keyof typeof EmojisingleselectcardrequestType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmojisingleselectcardrequestType = {
  emoji_single_select: 'emoji_single_select',
  emoji_picker: 'emoji_picker',
} as const
