import React, { createContext, useContext, useEffect, useMemo, ReactNode, useState } from 'react'
import { useIntl } from 'react-intl'

import { useAuthUser } from 'shared/providers/AuthUserProvider'
import { getAppEnv, getForerunnerApiKey, getForerunnerApiUrl } from 'shared/utils/env'
import { AppEnv } from 'shared/utils/env.types'

import type { SolidPaymentAdapter } from './utils/SolidPaymentAdapter'

interface PaymentContextInterface {
  isReady: boolean
  solidPaymentAdapter: SolidPaymentAdapter | null
  initSolidBankCard?: InstanceType<typeof SolidPaymentAdapter>['initSolidBankCard']
  initSolidPaypal?: InstanceType<typeof SolidPaymentAdapter>['initSolidPaypal']
  clearAllHandlers?: InstanceType<typeof SolidPaymentAdapter>['clearAllHandlers']
  clearPaypalHandlers?: InstanceType<typeof SolidPaymentAdapter>['clearPaypalHandlers']
  clearBankCardHandlers?: InstanceType<typeof SolidPaymentAdapter>['clearBankCardHandlers']
}

const PaymentContext = createContext<PaymentContextInterface | null>(null)

function usePayment() {
  const context = useContext(PaymentContext)

  if (!context) {
    throw new Error(`usePayment must be used within PaymentProvider`)
  }

  return context
}

interface PaymentProviderProps {
  children?: ReactNode
}

const PaymentProvider = ({ children }: PaymentProviderProps): JSX.Element => {
  const intl = useIntl()
  const {
    models: { user },
  } = useAuthUser()
  const [solidPaymentAdapter, setSolidPaymentAdapter] = useState<SolidPaymentAdapter | null>(null)

  useState(() =>
    import('./utils/SolidPaymentAdapter')
      .then((m) => m.SolidPaymentAdapter)
      .then((SolidPaymentAdapter) => {
        setSolidPaymentAdapter(new SolidPaymentAdapter())
      })
  )

  useEffect(() => {
    const apiUrl = getForerunnerApiUrl()
    const apiKey = getForerunnerApiKey()
    const appEnv = getAppEnv()

    solidPaymentAdapter?.solid.setHeaders({
      apiUrl: apiUrl,
      apiKey: apiKey,
      language: intl.locale,
      sandbox: appEnv !== AppEnv.PROD,
    })
  }, [intl.locale, solidPaymentAdapter?.solid])

  // provide api to components
  const api = useMemo<PaymentContextInterface>((): PaymentContextInterface => {
    return {
      isReady: Boolean(user),
      solidPaymentAdapter,
      initSolidBankCard: solidPaymentAdapter?.initSolidBankCard,
      initSolidPaypal: solidPaymentAdapter?.initSolidPaypal,
      clearAllHandlers: solidPaymentAdapter?.clearAllHandlers,
      clearPaypalHandlers: solidPaymentAdapter?.clearPaypalHandlers,
      clearBankCardHandlers: solidPaymentAdapter?.clearBankCardHandlers,
    }
  }, [solidPaymentAdapter, user])

  return <PaymentContext.Provider value={api}>{children}</PaymentContext.Provider>
}

export { PaymentProvider, usePayment }
