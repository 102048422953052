import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { HTMLAttributes, useEffect } from 'react'
import styled from 'styled-components'

import { useLocation } from 'shared/hooks/useLocation'

import { IS_DEVTOOLS_AVAILABLE } from 'shared/constants/devtools'
import { withTheme } from 'shared/hocs/withTheme'
import { AnalyticsProvider } from 'shared/providers/AnalyticsProvider'
import { AnalyticsAdapter } from 'shared/providers/AnalyticsProvider/helpers/AnalyticsAdapter'
import { AuthUserProvider } from 'shared/providers/AuthUserProvider'
import { ClientDataProvider } from 'shared/providers/ClientDataProvider'
import { FlowLoader } from 'shared/providers/FlowLoader'
import { FlowManagerProvider } from 'shared/providers/FlowManagerProvider'
import { OneTrustProvider } from 'shared/providers/OneTrustProvider'
import { PaymentProvider, SolidPaymentPreloader } from 'shared/providers/PaymentProvider'
import { appleLogin } from 'shared/utils/appleLogin'

import { onLoad } from './helpers'

import { OnboardingPropertiesInitializer } from '../OnboardingPropertiesInitializer'

const Devtools = dynamic(() => import('shared/components/Devtools').then((mod) => mod.Devtools), {
  ssr: false,
})

interface ContainerProps extends HTMLAttributes<HTMLElement> {}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Container = withTheme((props: ContainerProps) => {
  return <StyledContainer {...props} />
})

// create instance of analytics service
const AnalyticsService = new AnalyticsAdapter()
const ONE_TRUST_ID = process.env.NEXT_PUBLIC_ONE_TRUST_DOMAIN_ID

export const AppOnboarding = ({ Component, pageProps }: AppProps) => {
  const { country } = useLocation()

  useEffect(() => {
    appleLogin.initialize()
  }, [])

  return (
    <AuthUserProvider>
      <FlowLoader onLoad={onLoad}>
        <OneTrustProvider id={ONE_TRUST_ID} withAutoBlock>
          <AnalyticsProvider service={AnalyticsService} country={country}>
            <FlowManagerProvider currentScreenId={pageProps.id}>
              <ClientDataProvider>
                <PaymentProvider>
                  {IS_DEVTOOLS_AVAILABLE && <Devtools />}
                  <OnboardingPropertiesInitializer />
                  <Container>
                    <Component {...pageProps} />
                  </Container>
                  <SolidPaymentPreloader />
                </PaymentProvider>
              </ClientDataProvider>
            </FlowManagerProvider>
          </AnalyticsProvider>
        </OneTrustProvider>
      </FlowLoader>
    </AuthUserProvider>
  )
}
