import { createEmitter, SealedEvent } from 'ts-typed-events'

import { LEVELS_CONFIG } from './constants'
import { LoggerConfig, Level, LevelsConfig, MethodFn, LogFnParams, TransportFn } from './types'

export class Logger {
  #level: Level
  #levelsConfig: LevelsConfig
  #transports: Array<TransportFn>

  private emitters: {
    onLevelChange: ReturnType<typeof createEmitter<Level>>
  }

  events: {
    onLevelChange: SealedEvent<Level>
  }

  constructor(config: LoggerConfig | undefined = {}) {
    const { level = 'info', transports = [], levelsConfig = LEVELS_CONFIG } = config
    this.#level = level
    this.#transports = transports
    this.#levelsConfig = levelsConfig
    this.emitters = {
      onLevelChange: createEmitter<Level>(),
    }
    this.events = {
      onLevelChange: this.emitters.onLevelChange.event,
    }
  }

  get level(): Level {
    return this.#level
  }

  set level(value: Level) {
    this.#level = value
    this.emitters.onLevelChange.emit(value)
  }

  get levelsConfig(): LevelsConfig {
    return this.#levelsConfig
  }

  set levelsConfig(value: LevelsConfig) {
    this.#levelsConfig = value
  }

  get transports(): Array<TransportFn> {
    return this.#transports
  }

  set transports(value: Array<TransportFn>) {
    this.#transports = value
  }

  private log = <L>(options: LogFnParams<L>) => {
    const { value: levelValue } = this.levelsConfig[options.level]
    const isLevelEnabled = levelValue >= this.levelsConfig[this.level].value
    this.transports.forEach((transport) =>
      transport({ ...options, isLevelEnabled, levelsConfig: this.levelsConfig })
    )
  }

  silent = () => {}

  fatal: MethodFn<'fatal'> = (error, ...args: any[]) => {
    this.log<'fatal'>({ level: 'fatal', error, methodFnArgs: [...args] })
  }

  error: MethodFn<'error'> = (error, ...args: any[]) => {
    this.log<'error'>({ level: 'error', error, methodFnArgs: [...args] })
  }

  warn: MethodFn<'warn'> = (...args: any[]) => {
    this.log<'warn'>({ level: 'warn', methodFnArgs: [...args] })
  }

  info: MethodFn<'info'> = (...args: any[]) => {
    this.log<'info'>({ level: 'info', methodFnArgs: [...args] })
  }

  debug: MethodFn<'debug'> = (...args: any[]) => {
    this.log<'debug'>({ level: 'debug', methodFnArgs: [...args] })
  }

  trace: MethodFn<'trace'> = (...args: any[]) => {
    this.log<'trace'>({ level: 'trace', methodFnArgs: [...args] })
  }
}
