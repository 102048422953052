import Router from 'next/router'
import { useEffect } from 'react'

import { initLogger, logger } from 'shared/utils/logger'

function useLogger() {
  useEffect(() => {
    initLogger()
  }, [])

  useEffect(() => {
    const handleRouteChangeError = (err: unknown, url: string) => {
      const { cancelled } = err as { cancelled: boolean }

      if (!cancelled) {
        logger.info(`Router.routeChangeError from ${Router.asPath} to ${url}`)
        logger.error(Error('Router.routeChangeError', { cause: err }))
      }
    }

    Router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      Router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [])

  useEffect(() => {
    const handler = () => {
      if (document.visibilityState === 'hidden') {
        logger.debug('Page was hidden - visibilitychange event')
      }
    }

    global?.document.addEventListener('visibilitychange', handler)

    return () => {
      global?.document.removeEventListener('visibilitychange', handler)
    }
  }, [])
}

export { useLogger }
