/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Foodcravingsresponse = (typeof Foodcravingsresponse)[keyof typeof Foodcravingsresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Foodcravingsresponse = {
  sweets: 'sweets',
  salty: 'salty',
  alcohol: 'alcohol',
  any_food: 'any_food',
  never: 'never',
  other: 'other',
} as const
