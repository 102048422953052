/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Taskcontenttypeenumresponse =
  (typeof Taskcontenttypeenumresponse)[keyof typeof Taskcontenttypeenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Taskcontenttypeenumresponse = {
  article: 'article',
  survey: 'survey',
} as const
