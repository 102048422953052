/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Fitnesslevelenumresponse =
  (typeof Fitnesslevelenumresponse)[keyof typeof Fitnesslevelenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Fitnesslevelenumresponse = {
  beginner: 'beginner',
  intermediate: 'intermediate',
  advanced: 'advanced',
} as const
