/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Subscriptionstatusresponse =
  (typeof Subscriptionstatusresponse)[keyof typeof Subscriptionstatusresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Subscriptionstatusresponse = {
  active: 'active',
  paused: 'paused',
  cancelled: 'cancelled',
  redemption: 'redemption',
  refunded: 'refunded',
} as const
