/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Yesnorarelysometimesresponse =
  (typeof Yesnorarelysometimesresponse)[keyof typeof Yesnorarelysometimesresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Yesnorarelysometimesresponse = {
  yes_frequently: 'yes_frequently',
  sometimes: 'sometimes',
  rarely: 'rarely',
  no_never: 'no_never',
} as const
