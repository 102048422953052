import Cookies from 'js-cookie'

import { URL_PARAMS_COOKIE_NAME } from 'shared/providers/FlowLoader/constants'

const MAX_URL_PARAMS_LENGTH = 2048

export const getUrlParams = (props?: { parsed: boolean }) => {
  const { parsed } = props || {}
  let localUrlParams = localStorage?.getItem('urlParams') || window?.attr_urlParams

  if (!localUrlParams) {
    localUrlParams = Cookies.get(URL_PARAMS_COOKIE_NAME)
  }

  localUrlParams = localUrlParams || '{}'

  if (!parsed) {
    return localUrlParams
  }

  const length = localUrlParams.length

  if (length >= MAX_URL_PARAMS_LENGTH) {
    return { error: 'too_long', length }
  }

  try {
    return JSON.parse(localUrlParams)
  } catch (e) {
    return { error: 'parse_error' }
  }
}

export const urlParamsToObject = (urlSearchParams: URLSearchParams) => {
  const params: Record<string, string | string[]> = {}

  for (let paramName of urlSearchParams.keys()) {
    const values = urlSearchParams.getAll(paramName)

    params[paramName] = values.length === 1 ? values[0] : values
  }

  return params
}
