import Cookies from 'js-cookie'

import { BaseScreen, PaymentScreen } from 'shared/api/testania'
import { ScreenId } from 'shared/config/types'

import { logger } from './logger'

export const DEVTOOLS_COOKIE_PREFIX = 'frr-devtols_'
export const DEVTOOLS_CUSTOM_ONBOARDING_FLOW_COOKIE = `${DEVTOOLS_COOKIE_PREFIX}_custom_flow`
export const DEVTOOLS_CUSTOM_PAYMENT_FLOW_COOKIE = `${DEVTOOLS_COOKIE_PREFIX}_custom_payment_flow`
export const DEVTOOLS_FORCE_3DS = `${DEVTOOLS_COOKIE_PREFIX}_force3ds`

export const flowToTextValue = (flow: BaseScreen[]) =>
  flow.reduce((acc, { id }) => acc + '\n' + id, '').trim()

export const textValueToFlow = (value: string) =>
  value.split('\n').map((id) => ({ id, name: id, config: {} }))

export type PaymentProps = {
  payment?: ScreenId
  offer?: ScreenId
  upsell?: ScreenId
}

export const patchPaymentFlow = (
  paymentFlow: PaymentScreen[],
  { payment, offer, upsell }: PaymentProps
) => {
  return paymentFlow.map((screen) => {
    if (screen.id.match(/payment/) && payment) {
      return { ...screen, id: payment, name: payment }
    }

    if (screen.id.match(/offer/)) {
      return {
        ...screen,
        id: offer ?? screen.id,
        name: offer ?? screen.name,
        parent_id: payment ?? screen.parent_id,
      }
    }

    if (screen.id.match(/upsell/)) {
      let parentId = screen.parent_id

      if (parentId && parentId.match(/payment/) && payment) {
        parentId = payment
      }

      if (parentId && parentId.match(/offer/) && offer) {
        parentId = offer
      }

      return {
        ...screen,
        id: upsell ?? screen.id,
        name: upsell ?? screen.name,
        parent_id: parentId,
      }
    }

    return screen
  })
}

export const getCustomOnboardingFlow = () => Cookies.get(DEVTOOLS_CUSTOM_ONBOARDING_FLOW_COOKIE)

export const getCustomPaymentFlow = () => {
  const value = Cookies.get(DEVTOOLS_CUSTOM_PAYMENT_FLOW_COOKIE)

  if (value) {
    try {
      return JSON.parse(value)
    } catch (err) {
      logger.debug('Parse custom payment flow error:', err)
    }
  }

  return undefined
}

export const clearDevtoolsCookies = () => {
  const cookies = global.document.cookie.split('; ')

  const devtoolCookies = cookies
    .map((cookie) => {
      const [cookieName] = cookie.split('=')

      return cookieName
    })
    .filter((cookieName: string) => cookieName.startsWith(DEVTOOLS_COOKIE_PREFIX))

  for (const cookieName of devtoolCookies) {
    Cookies.remove(cookieName)
  }

  sessionStorage.clear()
}

export const getForce3dsFlag = () => {
  return Cookies.get(DEVTOOLS_FORCE_3DS) === 'true'
}

export const setForce3dsFlag = (value: boolean) => {
  return Cookies.set(DEVTOOLS_FORCE_3DS, String(value))
}

export const saveTextAsFile = (
  text: string,
  fileName: string,
  type = 'text/plain',
  download = false
) => {
  Object.assign(document.createElement('a'), {
    ...(download && { download: fileName }),
    href: URL.createObjectURL(new Blob([text], { type })),
    target: '_blank',
  }).click()
}
