import { useEventHandler } from 'shared/hooks/useEventHandler'

import { useAnalytics } from 'shared/providers/AnalyticsProvider'

export const useSendOnboardingEndEvent = () => {
  const { logEvent } = useAnalytics()

  return useEventHandler(() => {
    logEvent({ type: 'track', payload: { event: 'onboarding_end' } })
  })
}
