/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Taskiconenumresponse = (typeof Taskiconenumresponse)[keyof typeof Taskiconenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Taskiconenumresponse = {
  activity: 'activity',
  theory: 'theory',
} as const
