import { setTag, setUser } from '@sentry/nextjs'
import { useEffect } from 'react'

import { useLocation } from 'shared/hooks/useLocation'
import { useUpdateUserOnLastScreen } from 'shared/hooks/useUpdateUserOnLastScreen'

import { DEFAULT_GENDER } from 'shared/constants'
import { useAnalytics } from 'shared/providers/AnalyticsProvider'
import { useAuthUser } from 'shared/providers/AuthUserProvider'
import { useClientData } from 'shared/providers/ClientDataProvider'
import { UnitsSystem } from 'shared/providers/ClientDataProvider/constants/UnitsSystem'
import { useFlowLoader } from 'shared/providers/FlowLoader'
import { useRawFlowManager } from 'shared/providers/FlowManagerProvider'
import { Gender } from 'shared/types/user'

const IMPERIAL_SYSTEM_COUNTRIES = process.env.NEXT_PUBLIC_IMPERIAL_SYSTEM_COUNTRIES ?? ''

export const OnboardingPropertiesInitializer = () => {
  const {
    state: { gender },
    dispatch,
  } = useClientData()

  const {
    models: { onboardingFlow, currentScreen },
  } = useRawFlowManager() || {
    models: {},
  }

  useUpdateUserOnLastScreen(onboardingFlow, currentScreen?.id)

  const {
    models: { user },
  } = useAuthUser()
  const { country } = useLocation()

  const {
    models: { abTestVariables },
  } = useFlowLoader()
  const { setUserProperties } = useAnalytics()

  // set gender from ab test variable
  useEffect(() => {
    if (abTestVariables) {
      const { gender } = abTestVariables

      if (Object.values(Gender).includes(gender as Gender)) {
        dispatch({ type: 'SET_GENDER', payload: gender as Gender })
        setUserProperties({ gender })
      }
    }
  }, [abTestVariables, dispatch, setUserProperties])

  useEffect(() => {
    if (user) {
      const { id: userId = '' } = user
      setUser({ id: String(userId) })

      setUserProperties({ userId: String(userId), gender: gender || DEFAULT_GENDER })
    }
  }, [user, setUserProperties, gender])

  useEffect(() => {
    if (!country) return

    setTag('country', country)
    dispatch({ type: 'SET_COUNTRY', payload: country })

    if (IMPERIAL_SYSTEM_COUNTRIES.includes(country)) {
      dispatch({ type: 'SET_UNIT_SYSTEM', payload: UnitsSystem.IMPERIAL })
    }
  }, [country, dispatch])

  return null
}
