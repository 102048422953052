/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Buttonenumresponse = (typeof Buttonenumresponse)[keyof typeof Buttonenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Buttonenumresponse = {
  default: 'default',
  circle: 'circle',
  inverted: 'inverted',
} as const
