import { Router } from 'next/router'
import React from 'react'

import { useLocalization } from 'shared/providers/LocalizationProvider'
import { hideSplashScreen } from 'shared/utils/splashScreen'

export const SplashScreen = React.memo(() => {
  const [isNavFinished, setIsNavFinished] = React.useState(false)

  const {
    models: { isReady },
  } = useLocalization()

  React.useEffect(() => {
    const handleRouteChange = () => {
      setIsNavFinished(true)

      Router.events.off('routeChangeComplete', handleRouteChange)
    }

    Router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  React.useEffect(() => {
    if (isNavFinished && isReady) {
      hideSplashScreen()
    }
  }, [isNavFinished, isReady])

  return null
})
