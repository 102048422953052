/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type MobileemojisingleselectcardresponseType =
  (typeof MobileemojisingleselectcardresponseType)[keyof typeof MobileemojisingleselectcardresponseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MobileemojisingleselectcardresponseType = {
  emoji_single_select: 'emoji_single_select',
  emoji_picker: 'emoji_picker',
} as const
