export class Cache {
  private cache: Record<string, unknown | undefined>

  constructor() {
    this.cache = {}
  }

  set<TData extends unknown>(key: string, value: TData) {
    this.cache[key] = value
  }

  get<TData extends unknown>(key: string): TData | undefined {
    return this.cache[key] as TData
  }
}
