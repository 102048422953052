import { useEffect, useState } from 'react'

import { BODY_IN_WEBVIEW_CLASSNAME } from 'shared/constants/dom'

import { detectSocialWebViewBrowser } from './useDeviceOs'

export const useIsSocialWebViewBrowser = () => {
  const [isSocialWebViewBrowser, setIsSocialWebViewBrowser] = useState(false)

  useEffect(() => {
    setIsSocialWebViewBrowser(detectSocialWebViewBrowser())
  }, [])

  return isSocialWebViewBrowser
}

export const useDocumentSocialWebViewBrowser = () => {
  const isWebView = useIsSocialWebViewBrowser()

  useEffect(() => {
    const body = global?.document.body

    if (isWebView) {
      body.classList.add(BODY_IN_WEBVIEW_CLASSNAME)
    }

    return () => {
      body.classList.remove(BODY_IN_WEBVIEW_CLASSNAME)
    }
  }, [isWebView])
}
