import { Usersusercreatev2modelrequest } from 'shared/api/forerunner'
import { EatingProfile } from 'shared/constants/eatingProfile'
import { State } from 'shared/providers/ClientDataProvider'
import { Gender } from 'shared/types/user'
import { convertHeight, convertWeight } from 'shared/utils/unit-converters'

interface UserProperties
  extends Omit<Usersusercreatev2modelrequest, 'email' | 'branch_name' | 'timezone'> {
  eating_profile_type: EatingProfile
}

type NonNullableProperties<T> = {
  [K in keyof T]: NonNullable<T[K]>
}

export const clientToBackendProperties = (clientData: State, eatingProfile: EatingProfile) => {
  const {
    height,
    currentWeight,
    targetWeight,
    age,
    unitSystem,
    behaviorGuilt,
    behaviorPush,
    behaviorSocial,
    behaviorStPlate,
    behaviorStSocialize,
    gender,
    healthGoalReason,
    nutritionHowToFitInMyLife,
    healthGoals,
    goalsAdhd,
    additionalGoal,
    triedDiets,
    nutritionStressEating,
    occasionDate,
    // weightLossFocus,
    // weightLossMotivations,
    weightLossPace,
    adhdStatement1,
    adhdStatement2,
    adhdStatement3,
    adhdStatement4,
    adhdStatement5,
    adhdStatement6,
    adhdStatement7,
    adhdStatement8,
    adhdStatement9,
    fitnessLevel,
    currentBodyType,
    targetBodyType,
    lastHappyWeight,
    // childhoodTrauma,
    motivationChange,
    sleep,
    stressImage,
    previousAttempts,
    previousMentalHealth,
    knowBehavioral,
    baseStatement1,
    baseStatement2,
    baseStatement3,
    em1,
    em2,
    em3,
    em4,
    lifeEvents,
    adhdSuspect,
    adhdYN1,
    eatingBh,
    badHabits,
    unconsciousEating,
    foodRelax,
    sweetDrink,
  } = clientData as NonNullableProperties<typeof clientData>

  const metricHeight =
    height.value &&
    convertHeight({
      value: height.value,
      fromUnit: height.unit || 'cm',
      toUnit: 'cm',
      places: 2,
    })

  const metricWeight =
    currentWeight.value &&
    convertWeight({
      value: currentWeight.value,
      fromUnit: currentWeight.unit || 'kg',
      toUnit: 'kg',
      places: 2,
    })

  const metricTargetWeight =
    targetWeight.value &&
    convertWeight({
      value: targetWeight.value,
      fromUnit: targetWeight.unit || 'kg',
      toUnit: 'kg',
      places: 2,
    })

  const isUserChoseUnits = height.value || currentWeight.value || targetWeight.value

  const preparedParams: UserProperties = {
    height: metricHeight ? metricHeight.toString(10) : undefined,
    weight: metricWeight ? metricWeight.toString(10) : undefined,
    target_weight: metricTargetWeight ? metricTargetWeight.toString(10) : undefined,
    age: Number(age) || undefined,
    units: isUserChoseUnits ? unitSystem : undefined,
    gender: (gender as Gender) || undefined,
    behavior_guilt: behaviorGuilt,
    behavior_push: behaviorPush,
    behavior_social: behaviorSocial,
    behavior_st_plate: behaviorStPlate,
    behavior_st_socialize: behaviorStSocialize,
    eating_profile_type: eatingProfile,
    health_goal_reason: healthGoalReason,
    health_goals: healthGoals,
    goals_adhd: goalsAdhd,
    tried_diets: triedDiets,
    additional_goal: additionalGoal,
    nutrition_how_to_fit_in_my_life: nutritionHowToFitInMyLife,
    nutrition_stress_eating: nutritionStressEating,
    occasion_date: occasionDate?.toISOString(),
    // weight_loss_focus: weightLossFocus,
    // weight_loss_motivations: weightLossMotivations,
    weight_loss_pace: weightLossPace,
    fitness_level: fitnessLevel,
    current_body_type: currentBodyType,
    target_body_type: targetBodyType,
    last_happy_weight: lastHappyWeight,
    // childhood_trauma: childhoodTrauma,
    motivation_change: motivationChange,
    adhd_statement_1: adhdStatement1,
    adhd_statement_2: adhdStatement2,
    adhd_statement_3: adhdStatement3,
    adhd_statement_4: adhdStatement4,
    adhd_statement_5: adhdStatement5,
    adhd_statement_6: adhdStatement6,
    adhd_statement_7: adhdStatement7,
    adhd_statement_8: adhdStatement8,
    adhd_statement_9: adhdStatement9,
    sleep,
    stress_image: stressImage,
    previous_attempts: previousAttempts,
    previous_mental_health: previousMentalHealth,
    know_behavioral: knowBehavioral,
    base_statement_1: baseStatement1,
    base_statement_2: baseStatement2,
    base_statement_3: baseStatement3,
    life_events: lifeEvents,
    em1,
    em2,
    em3,
    em4,
    adhd_suspect: adhdSuspect,
    adhd_y_n_1: adhdYN1,
    eating_bh: eatingBh,
    bad_habits: badHabits,
    unconscious_eating: unconsciousEating,
    food_relax: foodRelax,
    sweet_drink: sweetDrink,
  }

  return preparedParams
}
