import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { screensConfig } from 'shared/config/screens'
import { ScreenId } from 'shared/config/types'
import { PAGE_TRANSITION_DURATION_MS } from 'shared/constants/animations'
import { eventEmitter } from 'shared/utils/eventEmitter'
import { logger } from 'shared/utils/logger'

interface RedirectToPageParams {
  delay?: number
  currentScreenId: ScreenId
  nextScreenUrl: string
}

export const useRedirectToPage = () => {
  const router = useRouter()

  const redirectToPage = ({
    delay = PAGE_TRANSITION_DURATION_MS,
    currentScreenId,
    nextScreenUrl,
  }: RedirectToPageParams) => {
    const method = screensConfig[currentScreenId].config?.replace ? 'replace' : 'push'

    eventEmitter.emit('routeChangeIntent')

    // delay before page transition to wait for animation end
    setTimeout(() => {
      router[method](nextScreenUrl).catch((err) => {
        logger.info(`Navigation error: next screen url - ${nextScreenUrl}`)
        logger.error(Error('[router] Navigation error', { cause: err }))
      })
    }, delay)
  }

  return useCallback(redirectToPage, [router])
}
