// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileExplorer = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#878E95"
        d="M1 15.767c0-.582.116-1.22.405-1.9L4.69 6.055C5.244 4.721 6.328 4 7.743 4c1.77 0 2.912 1.138 2.912 2.874v.54a5.632 5.632 0 0 1 2.69-.016V6.89c0-1.744 1.141-2.882 2.912-2.882 1.415 0 2.498.729 3.053 2.064l3.285 7.811a4.91 4.91 0 0 1 .405 1.9c0 2.4-2.151 4.217-4.84 4.217-2.68 0-4.816-1.818-4.816-4.217v-1.769a5.086 5.086 0 0 0-1.34-.163c-.48 0-.943.073-1.348.188v1.728c0 2.407-2.135 4.225-4.816 4.225-2.697 0-4.84-1.818-4.84-4.225Zm9.655-4.258c.406-.115.861-.189 1.35-.189.512 0 .95.05 1.34.164V9.937a5.844 5.844 0 0 0-1.34-.148c-.464 0-.928.057-1.35.172v1.548Zm-7.62 4.258c0 1.35 1.183 2.268 2.805 2.268 1.614 0 2.822-.91 2.822-2.268 0-1.351-1.208-2.26-2.822-2.26-1.622 0-2.805.909-2.805 2.26Zm12.303.016c0 1.351 1.208 2.26 2.822 2.26 1.622 0 2.805-.909 2.805-2.26 0-1.36-1.183-2.268-2.805-2.268-1.614 0-2.822.909-2.822 2.268Z"
      />
    </svg>
  )
}
export { IconProfileExplorer }
