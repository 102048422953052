/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Yesnoresponse = (typeof Yesnoresponse)[keyof typeof Yesnoresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Yesnoresponse = {
  yes: 'yes',
  no: 'no',
} as const
