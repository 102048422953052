import { LOG_LEVELS } from './constants'
import { Level, LogFnParams, ExceptionLogFnParams } from './types'

export const isLevel = (value?: unknown): value is Level => {
  return typeof value === 'string' && LOG_LEVELS.includes(value as Level)
}

export const isExceptionLogFnParams = (
  value: LogFnParams<Level>
): value is ExceptionLogFnParams => {
  return value.level === 'error' || value.level === 'fatal'
}
