/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Subscriptionchannelresponse =
  (typeof Subscriptionchannelresponse)[keyof typeof Subscriptionchannelresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Subscriptionchannelresponse = {
  card: 'card',
  paypal: 'paypal',
} as const
