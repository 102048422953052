// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileStoicWhite = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
      <g filter="url(#prefix__a)">
        <path
          fill="#fff"
          d="M13.39 36.781c-6.406 0-11.593-5.187-11.593-11.593 0-4.47 2.547-8.375 6.281-10.297v-.547c0-6.375 5.188-11.563 11.578-11.563S31.234 7.97 31.234 14.344c0 .187 0 .36-.015.531 3.75 1.922 6.312 5.828 6.312 10.313 0 6.406-5.187 11.593-11.578 11.593-2.312 0-4.469-.687-6.281-1.86a11.536 11.536 0 0 1-6.281 1.86Zm0-23.172c2.313 0 4.47.688 6.282 1.86a11.536 11.536 0 0 1 6.281-1.86c.672 0 1.328.063 1.969.172a8.282 8.282 0 0 0-8.266-7.703 8.283 8.283 0 0 0-8.265 7.703c.656-.11 1.312-.172 2-.172Zm0 3.313c-.53 0-1.062.047-1.562.14a8.286 8.286 0 0 0 3.235 4.188 11.119 11.119 0 0 1 2-3.469 8.092 8.092 0 0 0-3.672-.86Zm12.563 0a8.157 8.157 0 0 0-3.687.86 11.222 11.222 0 0 1 2 3.452 8.304 8.304 0 0 0 3.218-4.172c-.5-.093-1-.14-1.53-.14Zm0 16.562c4.578 0 8.281-3.718 8.281-8.297a8.281 8.281 0 0 0-3.687-6.89 11.603 11.603 0 0 1-5.594 6.36c0 .171.016.359.016.53 0 2.829-1.016 5.422-2.703 7.422a8.217 8.217 0 0 0 3.687.875Zm-12.562 0a8.102 8.102 0 0 0 3.671-.875c-1.687-2-2.687-4.593-2.687-7.422v-.515a11.69 11.69 0 0 1-5.61-6.36 8.26 8.26 0 0 0-3.671 6.875 8.292 8.292 0 0 0 8.297 8.297Zm6.265-10.843c.532 0 1.063-.047 1.563-.141a8.291 8.291 0 0 0-1.547-2.703 8.291 8.291 0 0 0-1.547 2.703c.5.094 1.016.14 1.531.14Zm0 3.297c-.672 0-1.328-.047-1.968-.172a8.27 8.27 0 0 0 1.984 4.828 8.254 8.254 0 0 0 1.969-4.828c-.641.109-1.297.172-1.985.172Z"
        />
      </g>
      <defs>
        <filter
          id="prefix__a"
          width={35.734}
          height={35}
          x={1.797}
          y={2.781}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1626_7607" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1626_7607" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
export { IconProfileStoicWhite }
