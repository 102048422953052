import { AnalyticUtils } from 'shared/providers/AnalyticsProvider/analytics'
import { System } from 'shared/providers/AnalyticsProvider/analytics/Interfaces/Systems'
import {
  AB_TEST_NAME_COOKIE_NAME,
  COUNTRY_COOKIE_NAME,
  URL_PARAMS_COOKIE_NAME,
} from 'shared/providers/FlowLoader/constants'
import { saveCookieValue } from 'shared/providers/FlowLoader/helpers'

import { AnalyticsEvent, AnalyticsEventWithTimestamp } from '../types'

interface UserProperties {
  country: string
  abTestName: string
  urlParams: string
  locale: string
  userId: string
  gender: string
}

export class AnalyticsAdapter {
  private eventsPool: Array<AnalyticsEventWithTimestamp>
  private userProperties: UserProperties
  private analyticsService: any
  private initialized: boolean
  private isEventSendingEnabled: boolean
  public analyticUtils: typeof AnalyticUtils | null

  constructor() {
    this.eventsPool = []

    this.userProperties = {
      gender: '',
      country: '',
      abTestName: '',
      urlParams: '',
      locale: '',
      userId: '',
    }
    this.initialized = false
    this.isEventSendingEnabled = false
    this.analyticUtils = null
  }

  setUserProperties = (userProperties: Partial<UserProperties>) => {
    if (userProperties.locale && userProperties.locale !== this.userProperties.locale) {
      // TODO: workaround for wikr analytics service
      global?.window?.localStorage?.setItem('language', userProperties.locale)
    }

    // TODO: workaround for wikr analytics service
    if (userProperties.abTestName && userProperties.abTestName !== this.userProperties.abTestName) {
      global?.window?.localStorage?.setItem('testania_name', userProperties.abTestName)
      global?.window && (global.window.attr_testania_name = userProperties.abTestName)
      saveCookieValue(AB_TEST_NAME_COOKIE_NAME, userProperties.abTestName)
    }

    // TODO: workaround for wikr analytics service
    if (userProperties.userId && userProperties.userId !== this.userProperties.userId) {
      this.analyticUtils?.setUserId(userProperties.userId)
    }

    // TODO: workaround for wikr analytics service
    if (userProperties.urlParams && userProperties.urlParams !== this.userProperties.urlParams) {
      global?.window?.localStorage?.setItem('urlParams', userProperties.urlParams)
      global?.window && (global.window.attr_urlParams = userProperties.urlParams)
      saveCookieValue(URL_PARAMS_COOKIE_NAME, userProperties.urlParams)
    }

    // TODO: workaround for wikr analytics service
    if (userProperties.gender && userProperties.gender !== this.userProperties.gender) {
      global?.window?.localStorage?.setItem('gender', userProperties.gender)
    }

    if (userProperties.country && userProperties.country !== this.userProperties.country) {
      this.analyticUtils?.setCountry(userProperties.country)
      saveCookieValue(COUNTRY_COOKIE_NAME, userProperties.country)
    }

    this.userProperties = {
      ...this.userProperties,
      ...userProperties,
    }
  }

  logEvent = (event: AnalyticsEvent, selectedSystems?: string[]) => {
    const eventWithTimestamp = {
      ...event,
      timestamp: Date.now(),
    }

    this.eventsPool.push(eventWithTimestamp)
    this.sendEvent(event, selectedSystems)
  }

  init() {
    return new Promise<boolean>((resolve, reject) => {
      import('shared/providers/AnalyticsProvider/analytics')
        .then((module) => {
          this.analyticsService = module.Main
          this.analyticsService.state.defaultTrackOptions.isPrefix = false
          this.analyticUtils = module.AnalyticUtils
          this.initialized = true

          /**
           * Expose events pool to window for testing purposes
           */
          // eslint-disable-next-line no-underscore-dangle
          global.window.__getEventsPool__ = () => this.eventsPool

          resolve(true)
        })
        .catch(() => {
          this.initialized = false
          reject()
        })
    })
  }

  activateEventSending = (settings: System[]) => {
    if (!this.initialized) {
      throw new Error('AnalyticsAdapter: not initialized')
    }

    this.isEventSendingEnabled = true

    this.analyticsService.initSystems({
      activeSystems: settings,
    })

    if (this.eventsPool.length) {
      for (const event of this.eventsPool) {
        const selectedSystems = settings.map(({ name }) => name)
        this.sendEvent(event, selectedSystems)
      }
    }
  }

  private sendEvent = (event: AnalyticsEvent, selectedSystems?: AnalyticsEvent['type'][]) => {
    if (
      this.initialized &&
      this.isEventSendingEnabled &&
      this.analyticsService &&
      typeof this.analyticsService[event.type] === 'function'
    ) {
      if (event.type === 'purchase') {
        return this.analyticsService[event.type](
          {
            ...event.payload.data,
            ab_test_name: this.userProperties.abTestName,
          },
          { selectedSystems }
        )
      }

      if (event.type === 'customData') {
        return this.analyticsService[event.type](
          event.payload.event,
          {
            ...event.payload.data,
          },
          { selectedSystems }
        )
      }

      return this.analyticsService[event.type](event.payload.event, { selectedSystems })
    }
  }

  reset = () => {
    this.analyticsService?.reset?.()
  }

  clearEventsPool = () => {
    this.eventsPool = []
  }
}
