import { Entries, Range, RangeOptions } from './types'

export const getQueryValueByKey = (key: string) => {
  const query = global?.window?.location?.search

  if (!query) {
    return ''
  }

  const urlSearchParams = new URLSearchParams(query)

  return urlSearchParams.get(key) || ''
}

export function entries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as any
}

export const validateRange = (
  value: number,
  range: Range,
  options: RangeOptions = { strict: false }
) => {
  const { from, to } = range
  const { strict } = options

  if (strict) {
    return Boolean(from < value && value < to)
  }

  return Boolean(from <= value && value <= to)
}

export const getGroupByValue = <T extends Record<string, Range>>(
  value: number,
  groupToRangeMap: T,
  defaultGroup: keyof T
): keyof T => {
  for (let [group, range] of entries(groupToRangeMap)) {
    if (validateRange(value, { from: range.from, to: range.to })) {
      return group
    }
  }

  return defaultGroup
}

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined
}
