// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileExplorerWhite = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={43} height={40} fill="none" {...props}>
      <g filter="url(#prefix__a)">
        <path
          fill="#fff"
          d="M.984 27.266c0-1.11.22-2.328.766-3.625L7.953 8.734C9 6.188 11.047 4.813 13.72 4.813c3.344 0 5.5 2.171 5.5 5.484v1.031A10.533 10.533 0 0 1 21.766 11c.89 0 1.75.094 2.53.297v-.969c0-3.328 2.157-5.5 5.5-5.5 2.673 0 4.72 1.39 5.767 3.938l6.203 14.906c.53 1.281.765 2.5.765 3.625 0 4.578-4.062 8.047-9.14 8.047-5.063 0-9.094-3.469-9.094-8.047v-3.375c-.735-.203-1.578-.313-2.531-.313-.907 0-1.782.141-2.547.36v3.297c0 4.593-4.032 8.062-9.094 8.062-5.094 0-9.14-3.469-9.14-8.062ZM19.22 19.14a9.283 9.283 0 0 1 2.547-.36c.968 0 1.797.094 2.53.313V16.14a10.927 10.927 0 0 0-2.53-.282c-.875 0-1.75.11-2.547.329v2.953Zm-14.39 8.125c0 2.578 2.234 4.328 5.296 4.328 3.047 0 5.328-1.735 5.328-4.328 0-2.578-2.281-4.313-5.328-4.313-3.063 0-5.297 1.735-5.297 4.313Zm23.234.03c0 2.579 2.28 4.313 5.328 4.313 3.062 0 5.297-1.734 5.297-4.312 0-2.594-2.235-4.328-5.297-4.328-3.047 0-5.328 1.734-5.328 4.328Z"
        />
      </g>
      <defs>
        <filter
          id="prefix__a"
          width={41.547}
          height={31.531}
          x={0.984}
          y={4.813}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1626_7647" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1626_7647" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
export { IconProfileExplorerWhite }
