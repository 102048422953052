/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Yesnosometimesrequest =
  (typeof Yesnosometimesrequest)[keyof typeof Yesnosometimesrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Yesnosometimesrequest = {
  yes: 'yes',
  no: 'no',
  sometimes: 'sometimes',
} as const
