import Head from 'next/head'
import React from 'react'

export const PageTitle = () => {
  return (
    <Head>
      <title>Mindea: Psychology & Nutrition</title>
    </Head>
  )
}
