import { useMemo } from 'react'

import { EatingProfile } from 'shared/constants/eatingProfile'

// @TODO check if needed
export const useDEBQQuizResults = () => {
  return useMemo(() => {
    const defaultLevel = {
      binary: 0,
      percent: 0,
    }

    // If there was no quiz in onboarding, then assign a default profile
    return {
      eatingProfile: EatingProfile.Pacifier,
      emotionalLevel: defaultLevel,
      externalLevel: defaultLevel,
      restrainedLevel: defaultLevel,
    }
  }, [])
}

export type QuizResultsReturnType = ReturnType<typeof useDEBQQuizResults>
