// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileRegulator = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#878E95"
        d="M12 22C6.517 22 2 17.485 2 11.995 2 6.515 6.508 2 12 2c5.483 0 10 4.515 10 9.995C22 17.485 17.492 22 12 22Zm0-2.49c4.167 0 7.51-3.35 7.51-7.515A7.495 7.495 0 0 0 12 4.49c-4.167 0-7.5 3.351-7.5 7.506a7.49 7.49 0 0 0 7.5 7.516ZM12 8a1.032 1.032 0 0 1-1.032-1.021c0-.568.464-1.032 1.032-1.032.559 0 1.023.464 1.023 1.032A1.03 1.03 0 0 1 12 8Zm-2.964.938a1.032 1.032 0 0 1-1.032-1.022c0-.568.464-1.032 1.032-1.032.559 0 1.023.464 1.023 1.032a1.03 1.03 0 0 1-1.023 1.022Zm5.928 0a1.032 1.032 0 0 1-1.032-1.022c0-.568.464-1.032 1.032-1.032.559 0 1.023.464 1.023 1.032a1.03 1.03 0 0 1-1.023 1.022ZM7.18 11.484a1.032 1.032 0 0 1-1.023-1.032A1.03 1.03 0 0 1 7.18 9.43c.568 0 1.032.464 1.032 1.022 0 .568-.464 1.032-1.032 1.032Zm9.63 0a1.034 1.034 0 0 1-1.032-1.032c0-.558.464-1.022 1.033-1.022a1.03 1.03 0 0 1 1.022 1.022c0 .568-.464 1.032-1.022 1.032ZM12 17.835a1.522 1.522 0 0 1-1.544-1.552c0-.142.048-.426.114-.729l.871-4.401c.067-.331.284-.502.559-.502.275 0 .492.17.568.502l.871 4.401c.067.303.114.587.114.729 0 .88-.672 1.552-1.553 1.552Z"
      />
    </svg>
  )
}
export { IconProfileRegulator }
