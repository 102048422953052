// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileGuardian = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#878E95"
        d="M4 13.752V6.57c0-1.388.433-1.95 1.584-2.465.903-.398 4.265-1.63 5.113-1.883.405-.116.902-.223 1.298-.223.397 0 .894.087 1.3.223.856.291 4.209 1.475 5.12 1.883C19.559 4.62 20 5.183 20 6.57v7.18c0 3.533-1.916 5.076-7.305 8.036-.248.126-.515.213-.7.213-.184 0-.451-.087-.69-.213C5.907 18.817 4 17.284 4 13.752Zm8.097 5.686c.092-.02.184-.068.359-.174 4.007-2.514 5.343-3.251 5.343-5.881V6.89c0-.369-.083-.524-.341-.63-1.317-.515-3.823-1.427-4.781-1.806-.276-.097-.424-.136-.58-.145v15.128Z"
      />
    </svg>
  )
}
export { IconProfileGuardian }
