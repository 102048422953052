import { withResolvers } from 'shared/utils/withResolvers'

import { SuccessLoginHandler, FailureLoginHandler } from './types'

import { generateHash } from '../hashString'

const appleScriptId = 'apple-login-script'
const clientId = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID
const redirectURI = process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI

const AppleLogin = () => {
  const { promise, resolve } = withResolvers()

  const isInitialized = () => !!global?.document.getElementById(appleScriptId)

  const awaitScriptLoad = () => promise

  const initialize = () => {
    if (isInitialized()) return

    const script = global?.window.document.createElement('script')
    script.setAttribute('id', appleScriptId)
    script.type = 'text/javascript'
    script.onload = () => {
      resolve?.(null)
    }

    script.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
    global.document.head.appendChild(script)
  }

  let isRendered = false
  const renderButton = ({
    onLoginSuccess,
    onLoginFailure,
  }: {
    onLoginSuccess: SuccessLoginHandler
    onLoginFailure: FailureLoginHandler
  }) => {
    if (isRendered || !clientId || !redirectURI) return () => {}

    const state = generateHash()

    const successHandler: SuccessLoginHandler = (data) => {
      if (state !== data.detail.authorization.state) return

      onLoginSuccess(data)
    }

    // @ts-expect-error
    document.addEventListener('AppleIDSignInOnSuccess', successHandler)
    document.addEventListener('AppleIDSignInOnFailure', onLoginFailure)

    awaitScriptLoad().then(() => {
      // @ts-expect-error
      global?.window.AppleID?.auth.init({
        clientId,
        scope: 'email name',
        redirectURI,
        state,
        usePopup: true,
      })
      isRendered = true
    })

    return () => {
      // @ts-expect-error
      document.removeEventListener('AppleIDSignInOnSuccess', successHandler)
      document.removeEventListener('AppleIDSignInOnFailure', onLoginFailure)
    }
  }

  return {
    initialize,
    renderButton,
    isButtonVisible: clientId && redirectURI,
  }
}

export const appleLogin = AppleLogin()
