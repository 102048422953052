import { MyApp as App } from 'shared/components/App/App'

import 'styles/colors/default.css'
import 'styles/fonts.css'
import 'styles/global.css'
import 'styles/tailwind.css'
import 'styles/themes/default.css'
import 'styles/typography.css'
import 'styles/slider.css'
import 'styles/notifications.css'

export default App
