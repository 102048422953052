import { createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react'

import { OT_BANNER_ROOT_ELEMENT_ID } from './constants'
import { OneTrustManager } from './helpers/OneTrustManager'
import { ExtendedWindow, OneTrustCookies } from './types'

// create instance of OneTrust service
const OneTrustService = new OneTrustManager()

interface OneTrustContextInterface {
  models: {
    allowedCookies: OneTrustCookies[] | null
    areAllCookiesAllowed: boolean
  }
  operations: {
    allowAllCookies: () => void
    enableCookieBannerVisibility: () => void
  }
}

export const OneTrustContext = createContext<OneTrustContextInterface | null>(null)

export const useOneTrust = () => {
  const context = useContext(OneTrustContext)

  if (!context) {
    throw new Error(`useOneTrust must be used within OneTrustProvider`)
  }

  return context
}

const MIN_ANIMATION_DELAY = 1500

interface OneTrustProviderProps {
  id: string | undefined
  withAutoBlock?: boolean
  children?: React.ReactNode
}

export const OneTrustProvider = ({
  id = '',
  withAutoBlock = false,
  children,
}: OneTrustProviderProps) => {
  const [isOtSDKLoaded, setIsOtSDKLoaded] = useState(false)
  const [allowedCookies, setAllowedCookies] = useState<OneTrustCookies[] | null>(null)
  const [shouldAllCookiesBeAllowed, setShouldAllCookiesBeAllowed] = useState(false)
  const [isBannerVisible, setIsBannerVisible] = useState(false)

  const areAllCookiesAllowed = useMemo(
    () => OneTrustService.checkAreAllCookiesAllowed(allowedCookies),
    [allowedCookies]
  )

  useEffect(() => {
    const extendedWindow = window as ExtendedWindow
    extendedWindow.onOneTrustLoaded = function () {
      setIsOtSDKLoaded(true)
    }
  }, [])

  // get user choice on cookies policy
  useEffect(() => {
    if (!window || !isOtSDKLoaded) {
      return
    }

    OneTrustService.getSelectedCookiesOptions({
      onSelect: (selectedOptions: OneTrustCookies[]) => {
        setAllowedCookies(selectedOptions)
      },
    })
  }, [isOtSDKLoaded])

  useEffect(() => {
    if (shouldAllCookiesBeAllowed && isOtSDKLoaded) {
      OneTrustService.allowAllCookies()
    }
  }, [shouldAllCookiesBeAllowed, isOtSDKLoaded])

  const allowAllCookies = useCallback(() => setShouldAllCookiesBeAllowed(true), [])

  const loadOtSDK = useCallback(() => {
    OneTrustService.init({
      domainId: id,
      withAutoBlock,
    })
  }, [id, withAutoBlock])

  const enableCookieBannerVisibility = useCallback(() => {
    setIsBannerVisible(true)
  }, [])

  useEffect(() => {
    if (isOtSDKLoaded && isBannerVisible) {
      const element = global.document.getElementById(OT_BANNER_ROOT_ELEMENT_ID)

      if (element) {
        setTimeout(() => {
          element.style.transition = 'opacity 550ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          element.style.opacity = '1'
          element.style.pointerEvents = 'all'
        }, MIN_ANIMATION_DELAY)
      }
    }
  }, [isOtSDKLoaded, isBannerVisible])

  useEffect(() => {
    loadOtSDK()
  }, [loadOtSDK])

  const api = useMemo<OneTrustContextInterface>(() => {
    return {
      models: {
        allowedCookies,
        areAllCookiesAllowed,
      },
      operations: {
        allowAllCookies,
        enableCookieBannerVisibility,
      },
    }
  }, [allowedCookies, areAllCookiesAllowed, allowAllCookies, enableCookieBannerVisibility])

  return <OneTrustContext.Provider value={api}>{children}</OneTrustContext.Provider>
}
