export enum SYSTEMS_NAMES {
  amazon = 'amazon',
  gtmDataLayer = 'gtmDataLayer',
  hyrosDataLayer = 'hyrosDataLayer',
  ga = 'ga',
}

export enum ENV {
  develop = 'develop',
  stage = 'stage',
  prod = 'prod',
}
