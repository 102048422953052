/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */
import { Applesubscriptionstatusresponse } from './applesubscriptionstatusresponse'
import { Subscriptionstatusresponse } from './subscriptionstatusresponse'

export type CurrentsubscriptionresponseStatus =
  (typeof CurrentsubscriptionresponseStatus)[keyof typeof CurrentsubscriptionresponseStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CurrentsubscriptionresponseStatus = {
  ...Subscriptionstatusresponse,
  ...Applesubscriptionstatusresponse,
} as const
