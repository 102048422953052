// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileEnthusiast = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#878E95"
        d="M12 22C6.517 22 2 17.483 2 12 2 6.508 6.508 2 12 2c5.483 0 10 4.508 10 10 0 5.483-4.508 10-10 10Zm-.956-11.477c.435.104.748.492 1.08.492.378 0 .728-.53 1.306-.53.606 0 .928.53 1.316.53.322 0 .625-.398 1.09-.492l-1.507-1.951c-.52-.672-1.268-.672-1.799.01l-1.486 1.94Zm-4.555 4.45h1.496c-.332-.558-.313-1.316.151-1.922l2.235-2.907-.113-.142c-.502-.55-1.184-.483-1.677.132l-2.698 3.447c-.464.588-.161 1.393.606 1.393Zm2.935 0h8.021c.871 0 1.117-.861.663-1.458l-1.667-2.168c-.596-.493-.956.303-1.704.303-.72 0-.985-.53-1.307-.53-.313 0-.587.53-1.307.53-.748 0-1.108-.796-1.704-.303l-1.667 2.168c-.455.597-.208 1.458.672 1.458Z"
      />
    </svg>
  )
}
export { IconProfileEnthusiast }
