// @TODO: remove post checkout flow from onboarding app
export const post_checkout_flow = [
  {
    id: 'ob_payment_success_register',
    name: 'ob_payment_success_register',
    config: {},
  },
  {
    id: 'ob_funnel_done_thank_you',
    name: 'ob_funnel_done_thank_you',
    config: {},
  },
]
