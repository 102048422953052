import { Level, isExceptionLogFnParams, TransportFn } from 'libs/logger'

type ConsoleMethod = 'error' | 'warn' | 'info' | 'debug' | 'trace'

const LEVEL_TO_CONSOLE_METHOD_MAP: Record<Level, ConsoleMethod | undefined> = {
  silent: undefined,
  fatal: 'error',
  error: 'error',
  warn: 'warn',
  info: 'info',
  debug: 'debug',
  trace: 'trace',
}

export const transport: TransportFn = (params) => {
  const { level, isLevelEnabled, methodFnArgs } = params
  let consoleArgs = [...methodFnArgs]

  if (isExceptionLogFnParams(params)) {
    const { error } = params
    consoleArgs = error.cause
      ? ['', error, `Caused by:`, error.cause, ...consoleArgs]
      : [error, ...consoleArgs]
  }

  if (isLevelEnabled) {
    const method = LEVEL_TO_CONSOLE_METHOD_MAP[level] ?? 'log'
    // eslint-disable-next-line no-console
    console[method](...consoleArgs)
  }
}
