/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Fitnesslevelenumrequest =
  (typeof Fitnesslevelenumrequest)[keyof typeof Fitnesslevelenumrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Fitnesslevelenumrequest = {
  beginner: 'beginner',
  intermediate: 'intermediate',
  advanced: 'advanced',
} as const
