/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Subscriptionchannelrequest =
  (typeof Subscriptionchannelrequest)[keyof typeof Subscriptionchannelrequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Subscriptionchannelrequest = {
  card: 'card',
  paypal: 'paypal',
} as const
