/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * testaniaWebApi
 * OpenAPI spec version: 1.0
 */

export type ProductPaymentType = (typeof ProductPaymentType)[keyof typeof ProductPaymentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductPaymentType = {
  subscription: 'subscription',
  lifetime: 'lifetime',
} as const
