// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileHarmoniser = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#878E95"
        d="M12 22C6.517 22 2 17.485 2 11.995 2 6.515 6.508 2 12 2c5.483 0 10 4.515 10 9.995C22 17.485 17.492 22 12 22Zm0-17.567h-.019H12Zm3.769 11.348c0 1.856-1.326 3.313-2.964 3.682a7.49 7.49 0 0 0 6.704-7.468A7.495 7.495 0 0 0 12 4.49c-.322 0-.644.02-.947.057a3.785 3.785 0 0 0-2.85 3.663c0 1.666 1.467 3.124 3.797 3.786 2.32.644 3.769 2.092 3.769 3.786Z"
      />
    </svg>
  )
}
export { IconProfileHarmoniser }
