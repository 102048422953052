/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Weightlosspaceresponse =
  (typeof Weightlosspaceresponse)[keyof typeof Weightlosspaceresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Weightlosspaceresponse = {
  asap: 'asap',
  slow: 'slow',
  in_between: 'in_between',
} as const
