// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconProfileEmpath = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#878E95"
        d="M12 22C6.517 22 2 17.485 2 11.995 2 6.515 6.508 2 12 2c5.483 0 10 4.515 10 9.995C22 17.485 17.492 22 12 22ZM9.699 7.774c-1.572 0-2.784 1.211-2.784 2.972 0 2.887 3.257 5.158 4.517 5.944.17.095.416.227.577.227.162 0 .38-.132.55-.227 1.26-.786 4.517-3.057 4.517-5.944 0-1.76-1.212-2.972-2.784-2.972-1.08 0-1.866.624-2.292 1.391-.436-.767-1.222-1.391-2.301-1.391Z"
      />
    </svg>
  )
}
export { IconProfileEmpath }
