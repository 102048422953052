/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Weightlosspacerequest =
  (typeof Weightlosspacerequest)[keyof typeof Weightlosspacerequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Weightlosspacerequest = {
  asap: 'asap',
  slow: 'slow',
  in_between: 'in_between',
} as const
