import { getColor } from 'shared/utils/color'
import { getShadow } from 'shared/utils/shadow'
import { getSpacing } from 'shared/utils/spacing'

import { breakpoints } from './breakpoints'
import { mediaQueries } from './mediaQueries'

export type Theme<T> = {
  name: T
  color: typeof getColor
  spacing: typeof getSpacing
  breakpoints: typeof breakpoints

  // Media queries in theme
  mq: typeof mediaQueries
  shadow: typeof getShadow
}

export enum ThemeNames {
  default = 'default',
}

export type ThemeName = keyof typeof ThemeNames

export type ThemesMap = {
  [Key in ThemeName]: Theme<Key>
}

export const themes: ThemesMap = {
  default: {
    name: 'default',
    color: getColor,
    spacing: getSpacing,
    breakpoints,
    mq: mediaQueries,
    shadow: getShadow,
  },
}
