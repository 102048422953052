/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Eatingprofilerequest = (typeof Eatingprofilerequest)[keyof typeof Eatingprofilerequest]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Eatingprofilerequest = {
  regulator: 'regulator',
  guardian: 'guardian',
  explorer: 'explorer',
  stoic: 'stoic',
  pacifier: 'pacifier',
  empath: 'empath',
  enthusiast: 'enthusiast',
  harmonizer: 'harmonizer',
  ios_default: 'ios_default',
} as const
