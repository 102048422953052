type EventHandler = () => void

type AvailableEvents = 'routeChangeIntent'
type EventHandlers = {
  [key in AvailableEvents]?: Array<EventHandler>
}

class EventEmitter {
  private events: EventHandlers = {}

  on(event: AvailableEvents, listener: EventHandler) {
    if (!this.events[event]) {
      this.events[event] = []
    }

    this.events[event]?.push(listener)
  }

  off(event: AvailableEvents, listener: EventHandler) {
    if (!this.events[event]) return
    this.events[event] = this.events[event]?.filter((l) => l !== listener)
  }

  emit(event: AvailableEvents) {
    if (!this.events[event]) return
    this.events[event]?.forEach((listener) => listener())
  }
}

export const eventEmitter = new EventEmitter()
