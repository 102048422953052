import { toInteger } from './numbers'

const MILLISECONDS_IN_HOUR = 3600000

export const getCurrentTimestamp = () => Date.now()

export const toDate = (value: Date | number): Date => {
  if (value instanceof Date) {
    return new Date(value.getTime())
  }

  return new Date(value)
}

export const addMilliseconds = (date: Date | number, amount: number) => {
  const timestamp = toDate(date).getTime()
  const intAmount = toInteger(amount)

  return new Date(timestamp + intAmount)
}

export const addHours = (date: Date | number, amount: number) => {
  const intAmount = toInteger(amount)

  return addMilliseconds(date, intAmount * MILLISECONDS_IN_HOUR)
}

export const getToday = () => new Date()
