import { useRef, useCallback } from 'react'

export const useEventHandler = <T extends (...args: any[]) => any>(eventHandler: T) => {
  const eventHandlerRef = useRef<T>(eventHandler)

  eventHandlerRef.current = eventHandler

  return useCallback((...args: Parameters<T>) => {
    return eventHandlerRef.current(...args)
  }, [])
}
