/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Actiontypeenumresponse =
  (typeof Actiontypeenumresponse)[keyof typeof Actiontypeenumresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Actiontypeenumresponse = {
  finish_scenario: 'finish_scenario',
  set_next_card_id: 'set_next_card_id',
} as const
