import Axios, { AxiosRequestConfig, AxiosError } from 'axios'

import { getTestaniaApiKey, getTestaniaApiUrl } from 'shared/utils/env'

if (!process.env.NEXT_PUBLIC_TESTANIA_PROJECT) {
  throw new Error('Testania project is required')
}

const axiosInstance = Axios.create({ baseURL: getTestaniaApiUrl() })

const DEFAULT_HEADERS = {
  'x-api-key': getTestaniaApiKey(),
  project: process.env.NEXT_PUBLIC_TESTANIA_PROJECT,
}

export const useHttpClient = <ResponseData>() => {
  return (config: AxiosRequestConfig<unknown>) => {
    config.headers = {
      ...DEFAULT_HEADERS,
    }

    const promise = axiosInstance({ ...config })
      .then((res) => {
        return {
          headers: res.headers as Record<string, string>,
          data: res.data as ResponseData,
        }
      })
      .catch()

    return promise
  }
}

export interface ErrorType<T = unknown> extends AxiosError<T> {
  error: string
}
