/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type Healthgoalreasonresponse =
  (typeof Healthgoalreasonresponse)[keyof typeof Healthgoalreasonresponse]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Healthgoalreasonresponse = {
  loose_weight: 'loose_weight',
  be_healthier: 'be_healthier',
  relationships_with_food: 'relationships_with_food',
  energy_increase: 'energy_increase',
  healthy_habits: 'healthy_habits',
} as const
